import axios from 'axios';
export const BASE_URL = "https://pakmallz.com/backend";
export const BASE_URL_IMAGES = "http://localhost:8000";
export const CHAT_GPT_KEY = "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";
export const CHAT_GPT_BASE_URL = 'https://api.openai.com/v1/chat/completions';

export function fetchData(endpoint) {
  return axios
    .get(`${BASE_URL}${endpoint}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
}

export function postData(endpoint, params) {
  return axios
    .post(`${BASE_URL}${endpoint}`, params)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
}