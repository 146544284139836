import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import "font-awesome/css/font-awesome.min.css";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

//--------------------------//
//          Theme           //
//--------------------------//
import "e-commerce-mobiles/assets/node_modules/select2/dist/css/select2.min.css";
import "e-commerce-mobiles/assets/node_modules/switchery/dist/switchery.min.css";
import "e-commerce-mobiles/assets/node_modules/bootstrap-select/bootstrap-select.min.css";
import "e-commerce-mobiles/assets/node_modules/bootstrap-tagsinput/dist/bootstrap-tagsinput.css";
import "e-commerce-mobiles/assets/node_modules/bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css";
import "e-commerce-mobiles/assets/node_modules/multiselect/css/multi-select.css";
import "e-commerce-mobiles/assets/node_modules/bootstrap-datepicker/bootstrap-datepicker.min.css";
import "e-commerce-mobiles/assets/node_modules/morrisjs/morris.css";
import "e-commerce-mobiles/dist/css/style.min.css";
import "e-commerce-mobiles/dist/css/pages/dashboard1.css";
import "e-commerce-mobiles/dist/css/pages/login-register-lock.css";
import 'icheck/skins/all.css';
import "e-commerce-mobiles/assets/node_modules/popper/popper.min.js";
import "e-commerce-mobiles/assets/node_modules/bootstrap/dist/js/bootstrap.js";
import "e-commerce-mobiles/dist/js/perfect-scrollbar.jquery.min.js";
import "e-commerce-mobiles/dist/js/waves.js";
import "e-commerce-mobiles/assets/node_modules/raphael/raphael-min.js";
import "e-commerce-mobiles/assets/node_modules/morrisjs/morris.min.js";
import "e-commerce-mobiles/assets/node_modules/jquery-sparkline/jquery.sparkline.min.js";
import "e-commerce-mobiles/assets/node_modules/switchery/dist/switchery.min.js";
import "e-commerce-mobiles/assets/node_modules/select2/dist/js/select2.full.min.js";
import "e-commerce-mobiles/assets/node_modules/bootstrap-select/bootstrap-select.min.js";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./redux/store";
import { Provider } from "react-redux";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import urTranslation from './locales/ur.json';
import arTranslation from './locales/ar.json';
import spTranslation from './locales/sp.json';
import { PersistGate } from "redux-persist/integration/react";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      ur: { translation: urTranslation },
      ar: { translation: arTranslation },
      sp: { translation: spTranslation },
    },
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false // React already does escaping
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </PersistGate>
  </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
