import { Card, Image } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../helpers/api";

const Variants = ({ variants, imageUrl, selectedColorVariantId, selectedStorageVariantId, setSelectedColorVariantId, setSelectedStorageVariantId }) => {
  return (
    <>
      {variants.colors && variants.colors.length > 0 && (
        <h6 style={{ fontWeight: "bold" }}>Colors</h6>
      )}
      <ul
        style={{
          listStyleType: "none",
          paddingLeft: "0rem",
          display: "inline-block",
        }}
      >
        {variants.colors &&
          variants.colors.length > 0 &&
          variants.colors.map((clr, key) => (
            <li style={{ display: "inline-block", marginRight: 5 }} key={key}>
              <Card className={`colors-card ${key === selectedColorVariantId ? 'active': ''}`} key={key} onClick={(e) => setSelectedColorVariantId(key)}>
                <Card.Body>
                  <div className="d-flex justify-content-center align-items-center" style={{ flexDirection: "column" }}>
                    <Image src={`${BASE_URL}/${imageUrl}`} width={40} />
                    <span className="color-variant-text" title={clr}>{clr}</span>
                  </div>
                </Card.Body>
              </Card>
            </li>
          ))}
      </ul>

      {variants.storages && variants.storages.length > 0 && (
        <h6 style={{ fontWeight: "bold" }}>Storage</h6>
      )}
      <div className="d-flex">
        {variants.storages &&
          variants.storages.length > 0 &&
          variants.storages.map((str, key) => (
            <Card className={`storage-card ${key === selectedStorageVariantId ? 'active': ''}`} key={key} onClick={(e) => setSelectedStorageVariantId(key)}>
              <Card.Body>
                {str}
              </Card.Body>
            </Card>
          ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  variants: state.home.selectedItem && state.home.selectedItem.variants ? state.home.selectedItem.variants : [],
  imageUrl: state.home.selectedItem.attachments.length > 0 ? state.home.selectedItem.attachments[0].url : null,
  selectedColorVariantId: state.home.selectedColorVariantId,
  selectedStorageVariantId: state.home.selectedStorageVariantId,
});

const dispatchStateToProps = (dispatch) => ({
  setSelectedColorVariantId: (value) => {
    dispatch({
      type: "UPDATE_SELECTED_COLOR_VARIANT_ID",
      payload: value
    })
  },
  setSelectedStorageVariantId: (value) => {
    dispatch({
      type: "UPDATE_SELECTED_STORAGE_VARIANT_ID",
      payload: value
    })
  }
});

export default connect(mapStateToProps, dispatchStateToProps)(Variants);
