import { Accordion, Button, Card, Col, Container, Form, FormControl, FormGroup, FormLabel, FormSelect, Image, Row } from "react-bootstrap";
import "./../../order.css";
import axios from "axios";
import { BASE_URL, fetchData } from "../../helpers/api";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import ThankYouPage from "./thankyou";
const Order = ({ selectedCurrency }) => {
    const [selectedItem, setSelectedItem] = useState({});
    const { id, name } = useParams();
    const [validated, setValidated] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [apiResponse, setApiResponse] = useState({});
    const [couponValue, setCouponValue] = useState('');
    const [formData, setFormData] = useState({
        email: '',
        firstname: '',
        lastname: '',
        phoneNumber: '',
        address: '',
        street: '',
        postalCode: '',
        payment_id: '',
        product_id: null,
        quantity: 1,
    });
    const getPaymentMethods = () => {
        axios(`${BASE_URL}/api/get_payment_methods`, {
            headers: {
                'Content-Type': "application/json"
            }
        }).then(response => {
            if (response.status === 200) {
                setPaymentMethods(response.data);
                if (response.data && response.data.length > 0) {
                    setFormData({ ...formData, payment_id: response.data[0].id });
                }
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const getSelectedItem = () => {
        fetchData(`/api/detail/${id}/${name}`)
            .then((response) => {
                setFormData({ ...formData, product_id: id });
                setSelectedItem(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        getPaymentMethods();
        getSelectedItem();
    }, [id, name]);

    const onFormSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        }

        axios.post(`${BASE_URL}/api/place_order`, formData, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then(response => {
            if (response.status === 200) {
                setApiResponse(response.data);
                setFormData({
                    email: '',
                    firstname: '',
                    lastname: '',
                    phoneNumber: '',
                    address: '',
                    street: '',
                    postalCode: '',
                    payment_id: '',
                    product_id: null,
                    quantity: 1,
                });
            }
            console.log(response);
        }).catch(err => {
            console.log(err);
        });
    }

    const sendOTPAsEmail = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            axios.post(`${BASE_URL}/api/send_otp_email`, { email: formData.email }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                if (res.status === 200) {
                    console.log(res);
                }
            }).catch(err => {
                console.log(err);
            })
        }
    }

    const applyCoupon = () => {
        axios.post(`${BASE_URL}/api/get_coupon_by_code`, { code: couponValue }, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            console.log(res);
            if (res.status === 200) {
                const fromDate = new Date(res?.data?.data?.valid_from);
                const toDate = new Date(res?.data?.data?.valid_to);
                const currentDate = new Date();

                if (currentDate >= fromDate && currentDate <= toDate) {
                    console.log("valid");
                } else {
                    console.log("invalid coupon code");
                }
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const calculateTotal = () => {
        return selectedItem.prices && selectedItem.prices.length > 0 && parseFloat(selectedItem.prices[0]?.price_rs).toLocaleString('en-US', {
            style: 'currency',
            currency: selectedCurrency && selectedCurrency.code ? selectedCurrency.code : 'pkr',
        });
    }

    return (
        <Form noValidate validated={validated} onSubmit={onFormSubmit}>
            <Container className="mt-3">
                {apiResponse && Object.keys(apiResponse).length > 0 ?
                    <ThankYouPage />
                    :
                    <Row>
                        <Col xs={12} md={8}>
                            {/* <div className="mb-3 pe-2">
                                <div id="order-steps">
                                    <ul className="progressbar">
                                        <li className="text-white active">
                                            <span className="p5">OTP Verification</span>
                                        </li>
                                        <li className="text-dark">
                                            <span className="p5">Contact Info</span>
                                        </li>
                                        <li className="text-dark">
                                            <span className="p5 text-left">Delivery</span>
                                        </li>
                                        <li><span className="text-dark p5">Payment</span></li>
                                    </ul>
                                </div>
                            </div> */}


                            <Card>
                                <Card.Body>
                                    <h3 className={`${formData.email === '' ? '' : 'text-muted'}`}>1. Email Address</h3>
                                    <div>
                                        <FormGroup className="mb-2 col-md-6 col-sm-12 p-0">
                                            <FormControl
                                                required
                                                value={formData.email}
                                                type="email"
                                                placeholder="example@gmail.com"
                                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                onBlur={sendOTPAsEmail}
                                                onKeyUp={(e) => sendOTPAsEmail(e)}
                                            />
                                        </FormGroup>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Card>
                                <Card.Body>
                                    <h3 className="text-muted">2. Contact Information</h3>
                                    <div className="show">
                                        <Row className="mb-2">
                                            <FormGroup className="col-md-6 col-sm-12">
                                                <FormLabel>First Name</FormLabel>
                                                <FormControl
                                                    required
                                                    value={formData.firstname}
                                                    type="text"
                                                    placeholder="Enter your first name"
                                                    onChange={(e) => setFormData({ ...formData, firstname: e.target.value })} />
                                            </FormGroup>
                                            <FormGroup className="col-md-6 col-sm-12">
                                                <FormLabel>Last Name</FormLabel>
                                                <FormControl
                                                    required
                                                    value={formData.lastname}
                                                    type="text"
                                                    placeholder="Enter your last name"
                                                    onChange={(e) => setFormData({ ...formData, lastname: e.target.value })} />
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup className="col-md-12 col-sm-12">
                                                <FormLabel>Phone Number</FormLabel>
                                                <FormControl
                                                    required
                                                    value={formData.phoneNumber}
                                                    type="text"
                                                    placeholder="Enter your phone number"
                                                    onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })} />
                                            </FormGroup>
                                        </Row>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Card>
                                <Card.Body>
                                    <h3 className="text-muted">3a. Delivery Address</h3>
                                    <div className="show">
                                        <Row className="mb-2">
                                            <FormGroup className="col-md-12 col-sm-12">
                                                <FormLabel>Address</FormLabel>
                                                <FormControl
                                                    required
                                                    value={formData.address}
                                                    type="text"
                                                    placeholder="Enter your address"
                                                    onChange={(e) => setFormData({ ...formData, address: e.target.value })} />
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup className="col-md-6 col-sm-12">
                                                <FormLabel>Street</FormLabel>
                                                <FormControl
                                                    required
                                                    value={formData.street}
                                                    type="text"
                                                    placeholder="Enter your street"
                                                    onChange={(e) => setFormData({ ...formData, street: e.target.value })} />
                                            </FormGroup>
                                            <FormGroup className="col-md-6 col-sm-12">
                                                <FormLabel>Postal Code</FormLabel>
                                                <FormControl
                                                    required
                                                    value={formData.postalCode}
                                                    type="text"
                                                    placeholder="Enter your postal code"
                                                    onChange={(e) => setFormData({ ...formData, postalCode: e.target.value })} />
                                            </FormGroup>
                                        </Row>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Card>
                                <Card.Body>
                                    <h3 className="text-muted">3b. Delivery Type</h3>
                                    <div className="show">
                                        <Row>
                                            <FormGroup className="col-md-6 col-sm-12">
                                                <FormLabel>Delivery Method</FormLabel>
                                                <FormSelect required value={formData.payment_id} onChange={(e) => setFormData({ ...formData, payment_id: e.target.value })}>
                                                    {paymentMethods && paymentMethods.length > 0 && paymentMethods.map((val, key) => (
                                                        <option value={val.id} key={key}>{val.name}</option>
                                                    ))}
                                                </FormSelect>
                                            </FormGroup>
                                        </Row>
                                    </div>
                                </Card.Body>
                            </Card>
                            <div className="mb-3">
                                <Button type="submit">Proceed To Order</Button>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <h3 className="mt-0">Order Summary</h3>
                            {selectedItem &&
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col xs={12} md={4}>
                                                {selectedItem.attachments && selectedItem.attachments.length > 0 && <Image src={`${BASE_URL}/${selectedItem.attachments[0]?.url}`} width={'100%'} />}
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <h3>{selectedItem.item_name}</h3>
                                                {<p className="text-muted">{selectedItem.prices && selectedItem.prices.length > 0 && parseFloat(selectedItem.prices[0]?.price_rs).toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: selectedCurrency && selectedCurrency.code ? selectedCurrency.code : 'pkr',
                                                })}</p>}
                                                {selectedItem.variants && selectedItem.variants.colors && selectedItem.variants.colors.length > 0 &&
                                                    <div>
                                                        <span>Color</span>
                                                        <p>{selectedItem.variants.colors[0]}</p>
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <h3>Price Detail</h3>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6} md={6}>
                                                <h4>Market Price</h4>
                                            </Col>
                                            <Col xs={6} md={6} className="text-right">
                                                <h4>{selectedItem.prices && selectedItem.prices.length > 0 && parseFloat(selectedItem.prices[0]?.price_rs).toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: selectedCurrency && selectedCurrency.code ? selectedCurrency.code : 'pkr',
                                                })}</h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6} md={6}>
                                                <h4>Sale Price</h4>
                                            </Col>
                                            <Col xs={6} md={6} className="text-right">
                                                <h4>{selectedItem.prices && selectedItem.prices.length > 0 && parseFloat(selectedItem.prices[0]?.price_rs).toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: selectedCurrency && selectedCurrency.code ? selectedCurrency.code : 'pkr',
                                                })}</h4>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={6} md={6}>
                                                <h4>Delivery Charges</h4>
                                            </Col>
                                            <Col xs={6} md={6} className="text-right">
                                                <h4>0</h4>
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col xs={6} md={6}>
                                                <h3>Total</h3>
                                            </Col>
                                            <Col xs={6} md={6} className="text-right">
                                                <h4>{calculateTotal()}</h4>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            }

                            <Card>
                                <Card.Body>
                                    <Accordion
                                        defaultActiveKey="0"
                                        flush
                                    >
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Apply Coupon</Accordion.Header>
                                            <Accordion.Body style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                <FormGroup>
                                                    <Row>
                                                        <Col xs={12} md={8} className="p-0">
                                                            <FormControl
                                                                value={couponValue}
                                                                onChange={(e) => setCouponValue(e.target.value)}
                                                                type="text"
                                                                placeholder="Coupon Code"
                                                            />
                                                        </Col>
                                                        <Col xs={12} md={4} className="order-apply-column">
                                                            <Button style={{ width: "100%" }} onClick={() => applyCoupon()}>Apply</Button>
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }
            </Container>
        </Form>
    )
}

const mapStateToProps = (state) => ({
    //selectedItem: state.home.selectedItem
    selectedCurrency: state.home.selectedCurrency
});

export default connect(mapStateToProps, {})(Order);