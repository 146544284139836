import { useEffect, useState } from "react";
import Select from "react-select";
import { postData } from "../helpers/api";
import { Form } from "react-bootstrap";
import { FaSearch } from 'react-icons/fa';

const Myselect = ({ title, onSelect, selectedName }) => {
  const [inputValue, setInputValue] = useState(selectedName);
  const [options, setOptions] = useState([]);

  const handleChange = (selectedOption) => {
    onSelect(selectedOption);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: '45px'
    }),
    indicatorSeparator: () => ({
      display: "none", // Hide separator
    }),
    dropdownIndicator: () => ({
      display: "none", // Hide right angle indicator
    }),
  };

  const loadOptions = (inputValue, callback) => {
    postData("/api/search", { type:"mobile", keyword: inputValue })
      .then((response) => {
        // Format the response data into options array
        const formattedOptions = response.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        // Update the options state
        setOptions(formattedOptions);

        // Invoke the callback with the options
        callback(formattedOptions);
      })
      .catch((error) => {
        console.log(error);
        callback([]);
      });
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);

    // Call the loadOptions function with the new value to fetch new options
    loadOptions(newValue, (newOptions) => {
      setOptions(newOptions);
    });
  };

  useEffect(() => {
    if (selectedName) {
      loadOptions(selectedName, (newOptions) => {
        setOptions(newOptions);
        if(newOptions && newOptions.length > 0) {
          onSelect(newOptions[0]);
        }
      });
    }
  }, [selectedName]);

  const customDropdownIndicator = (props) => {
    return (
      <div {...props} style={{marginRight: 15}}>
        <FaSearch />
      </div>
    );
  }

  return (
    <Form.Group>
      {title && <Form.Label>{title}</Form.Label>}
      <Select
        components={{ DropdownIndicator: customDropdownIndicator }}
        styles={customStyles}
        options={options}
        onChange={handleChange}
        onInputChange={handleInputChange}
        loadOptions={loadOptions}
        placeholder="Search..."
        inputValue={inputValue}
        isSearchable={true}
      />
    </Form.Group>
  );
};

export default Myselect;