import { Carousel, Col, Container, Row, Table } from "react-bootstrap";
import { BASE_URL, fetchData } from "../../helpers/api";
import React, { useState } from "react";
import Rating from "../rating";
import Myselect from "../myselect";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { handleImageError, renderPrice } from "../../helpers/common";

const Compare = ({ selectedItem }) => {
  const { id } = useParams();
  const [specification1, setSpecification1] = useState({
    id: null,
    name: "",
    rating: 0,
    specifications: {
      details: [],
      attachments: [],
    },
  });
  const [specification2, setSpecification2] = useState({
    id: null,
    name: "",
    rating: 0,
    specifications: {
      details: [],
      attachments: [],
    },
  });
  const [specification3, setSpecification3] = useState({
    id: null,
    name: "",
    rating: 0,
    specifications: {
      details: [],
      attachments: [],
    },
  });

  const handleSelectOne = (item) => {
    fetchData(
      `/api/compare/${item.value}/${specification2 && Object.keys(specification2).length > 0
        ? specification2.id
        : null
      }/${specification3 && Object.keys(specification3).length > 0
        ? specification3.id
        : null
      }`
    )
      .then((response) => {
        setSpecification1(response.specification1);
        setSpecification2(response.specification2);
        setSpecification3(response.specification3);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelectTwo = (item) => {
    fetchData(
      `/api/compare/${specification1 && Object.keys(specification1).length > 0
        ? specification1.id
        : null
      }/${item.value}/${specification3 && Object.keys(specification3).length > 0
        ? specification3.id
        : null
      }`
    )
      .then((response) => {
        setSpecification1(response.specification1);
        setSpecification2(response.specification2);
        setSpecification3(response.specification3);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSelectThree = (item) => {
    fetchData(
      `/api/compare/${specification1 && Object.keys(specification1).length > 0
        ? specification1.id
        : null
      }/${specification2 && Object.keys(specification2).length > 0
        ? specification2.id
        : null
      }/${item.value}`
    )
      .then((response) => {
        setSpecification1(response.specification1);
        setSpecification2(response.specification2);
        setSpecification3(response.specification3);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removeSelectedItem = (whichOne) => {
    if (whichOne === "one") {
      setSpecification1({
        id: null,
        name: "",
        rating: 0,
        specifications: {
          details: [],
          attachments: [],
        },
      });
    } else if (whichOne === "two") {
      setSpecification2({
        id: null,
        name: "",
        rating: 0,
        specifications: {
          details: [],
          attachments: [],
        },
      });
    } else if (whichOne === "three") {
      setSpecification3({
        id: null,
        name: "",
        rating: 0,
        specifications: {
          details: [],
          attachments: [],
        },
      });
    }
  };

  return (
    <Container className="mb-3 mt-3">
      <Row className="mb-2">
        <Col xs={12} md={4}>
          <Myselect
            title="Compare With"
            onSelect={handleSelectOne}
            selectedName={selectedItem && Object.keys(selectedItem).length > 0 ? selectedItem.name : ""}
          />
        </Col>
        <Col xs={12} md={4}>
          <Myselect title="Compare With" onSelect={handleSelectTwo} />
        </Col>
        <Col xs={12} md={4}>
          <Myselect title="Compare With" onSelect={handleSelectThree} />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <div className="d-flex justify-content-center align-items-center">
            {specification1 &&
              specification1.specifications &&
              specification1.specifications.attachments &&
              specification1.specifications.attachments.length > 0 && (
                <div>
                  <div className="compare-close-btn">
                    <span onClick={() => removeSelectedItem("one")}>
                      <i className="fa fa-close"></i>
                    </span>
                  </div>
                  
                  <Carousel indicators={false} className="mobile-attachment-slider" style={{ maxWidth: 200 }}>
                      {specification1?.specifications?.attachments.map((slide, keyy) => (
                          <Carousel.Item key={keyy}>
                              <img
                                  className="d-block w-100"
                                  style={{ height: 250, maxHeight: 250, maxWidth: 180 }}
                                  src={`${BASE_URL}/${slide.url}`}
                                  alt="Second slide"
                                  onError={(e) => handleImageError(e, "compare")}
                              />
                          </Carousel.Item>
                      ))}
                  </Carousel>
                </div>
              )}
          </div>
          <br />
          {specification1 && specification1.rating ? (
            <Row className="mb-2">
              <Col>Ratings</Col>
              <Col className="d-flex justify-content-end">
                <Rating
                  count={specification1.rating}
                  editing={false}
                  isTextShow={true}
                />
              </Col>
            </Row>
          ) : null}
          <Table bordered striped hover>
            <tbody>
              {specification1 &&
                specification1?.specifications &&
                specification1?.specifications?.details &&
                specification1?.specifications?.details.length > 0 &&
                specification1?.specifications?.details.map((specs) => {
                  if (specs.details && Object.keys(specs.details).length > 1) {
                    return (
                      <React.Fragment key={specs.name}>
                        <tr>
                          <th
                            className="text-left align-top w-25 primary-color"
                            style={{ minWidth: "0", padding: "2px" }}
                            rowSpan={Object.keys(specs.details).length + 1}
                          >
                            {specs.name}
                          </th>
                        </tr>
                        {Object.entries(specs.details).map(([key, value]) => (
                          <tr key={key}>
                            <td className="text-left info-color">
                              <strong>{specs.name === 'Prices' ? value?.name : key}</strong>
                            </td>
                            <td className="text-left">
                              <span className="custom-wrap-single-line">
                                {value?.value}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    );
                  } else if (specs.details && Object.keys(specs.details).length === 1) {
                    return (
                      <React.Fragment key={specs.name}>
                        {Object.entries(specs.details).map(([key, value]) => (
                          <tr key={value?.name}>
                            <th
                              className="text-left align-top w-25 primary-color"
                              style={{ minWidth: "0", padding: "2px" }}
                            >
                              {specs.name}
                            </th>
                            <td className="text-left info-color">
                              <strong>{value?.name}</strong>
                            </td>
                            <td className="text-left">
                              <span className="custom-wrap-single-line">
                                {value?.name === "in Rupees" ||
                                  value?.name === "in USD"
                                  ? renderPrice(value?.value)
                                  : value?.value}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    );
                  } else {
                    return null;
                  }
                })}
            </tbody>
          </Table>
        </Col>
        <Col xs={12} md={4}>
          <div className="d-flex justify-content-center align-items-center">
            {specification2 &&
              specification2?.specifications &&
              specification2?.specifications?.attachments &&
              specification2?.specifications?.attachments.length > 0 && (
                <div>
                  <div className="compare-close-btn">
                    <span onClick={() => removeSelectedItem("two")}><i className="fa fa-close"></i></span>
                  </div>
                  <img
                    src={`${BASE_URL}/${specification2?.specifications?.attachments[0]?.url}`}
                    alt="img"
                    style={{ maxHeight: 250, maxWidth: 180, height: 250 }}
                    onError={(e) => handleImageError(e, "compare")}
                  />
                </div>
              )}
          </div>
          <br />
          {specification2 && specification2?.rating ? (
            <Row className="mb-2">
              <Col>Ratings</Col>
              <Col className="d-flex justify-content-end">
                <Rating
                  count={specification2?.rating}
                  editing={false}
                  isTextShow={true}
                />
              </Col>
            </Row>
          ) : null}
          <Table bordered striped hover>
            <tbody>
              {specification2 &&
                specification2?.specifications &&
                specification2?.specifications?.details &&
                specification2?.specifications?.details.length > 0 &&
                specification2.specifications.details.map((specs) => {
                  if (specs.details && Object.keys(specs.details).length > 1) {
                    return (
                      <React.Fragment key={specs.name}>
                        <tr>
                          <th
                            className="text-left align-top w-25 primary-color"
                            style={{ minWidth: "0", padding: "2px" }}
                            rowSpan={Object.keys(specs.details).length + 1}
                          >
                            {specs.name}
                          </th>
                        </tr>
                        {Object.entries(specs.details).map(([key, value]) => (
                          <tr key={key}>
                            <td className="text-left info-color">
                              <strong>{specs.name === 'Prices' ? value?.name : key}</strong>
                            </td>
                            <td className="text-left">
                              <span className="custom-wrap-single-line">
                                {value?.value}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    );
                  } else if (specs.details && Object.keys(specs.details).length === 1) {
                    return (
                      <React.Fragment key={specs.name}>
                        {Object.entries(specs.details).map(([key, value]) => (
                          <tr key={key}>
                            <th
                              className="text-left align-top w-25 primary-color"
                              style={{ minWidth: "0", padding: "2px" }}
                            >
                              {specs.name}
                            </th>
                            <td className="text-left info-color">
                              <strong>{value.name}</strong>
                            </td>
                            <td className="text-left">
                              <span className="custom-wrap-single-line">
                                {value.name === "in Rupees" ||
                                  value.name === "in USD"
                                  ? renderPrice(value.value)
                                  : value.value}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    );
                  } else {
                    return null;
                  }
                })}
            </tbody>
          </Table>
        </Col>
        <Col xs={12} md={4}>
          <div className="d-flex justify-content-center align-items-center">
            {specification3 &&
              specification3?.specifications &&
              specification3?.specifications?.attachments &&
              specification3?.specifications?.attachments.length > 0 && (
                <div>
                  <div className="compare-close-btn">
                    <span onClick={() => removeSelectedItem("three")}><i className="fa fa-close"></i></span>
                  </div>
                  <img
                    src={`${BASE_URL}/${specification3?.specifications?.attachments[0]?.url}`}
                    alt="img"
                    style={{ maxHeight: 250, maxWidth: 180, height: 250 }}
                    onError={(e) => handleImageError(e, "compare")}
                  />
                </div>
              )}
          </div>
          <br />
          {specification3 && specification3?.rating ? (
            <Row className="mb-2">
              <Col>Ratings</Col>
              <Col className="d-flex justify-content-end">
                <Rating
                  count={specification3?.rating}
                  editing={false}
                  isTextShow={true}
                />
              </Col>
            </Row>
          ) : null}
          <Table bordered striped hover>
            <tbody>
              {specification3 &&
                specification3?.specifications &&
                specification3?.specifications?.details &&
                specification3?.specifications?.details.length > 0 &&
                specification3?.specifications?.details.map((specs) => {
                  if (specs.details && Object.keys(specs.details).length > 1) {
                    return (
                      <React.Fragment key={specs.name}>
                        <tr>
                          <th
                            className="text-left align-top w-25 primary-color"
                            style={{ minWidth: "0", padding: "2px" }}
                            rowSpan={Object.keys(specs.details).length + 1}
                          >
                            {specs.name}
                          </th>
                        </tr>
                        {Object.entries(specs.details).map(([key, value]) => (
                          <tr key={key}>
                            <td className="text-left info-color">
                              <strong>{specs.name === 'Prices' ? value?.name : key}</strong>
                            </td>
                            <td className="text-left">
                              <span className="custom-wrap-single-line">
                                {value?.value}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    );
                  } else if (specs.details && Object.keys(specs.details).length === 1) {
                    return (
                      <React.Fragment key={specs.name}>
                        {Object.entries(specs.details).map(([key, value]) => (
                          <tr key={value?.name}>
                            <th
                              className="text-left align-top w-25 primary-color"
                              style={{ minWidth: "0", padding: "2px" }}
                            >
                              {specs.name}
                            </th>
                            <td className="text-left info-color">
                              <strong>{value?.name}</strong>
                            </td>
                            <td className="text-left">
                              <span className="custom-wrap-single-line">
                                {value?.name === "in Rupees" ||
                                  value?.name === "in USD"
                                  ? renderPrice(value?.value)
                                  : value?.value}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    );
                  } else {
                    return null;
                  }
                })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  selectedItem: state.home.selectedItemForCompare,
});

const dispatchStateToProps = (dispatch) => ({
  
})
export default connect(mapStateToProps, dispatchStateToProps)(Compare);
