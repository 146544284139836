import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchData } from "../../helpers/api";
import { useEffect} from "react";
import { Card, Col, Container, Placeholder, Row } from "react-bootstrap";
import Productslider from "../productslider";
import Rating from "../rating";
import Unboxingvideos from "../unboxingvideos";
import Specifications from "../specifications";
import Reviews from "./reviews";
import Prices from "../prices";
import Prominentspecs from "../prominentspecs";
import _ from "lodash";
import Variants from './../includes/variants';
import { useTranslation } from "react-i18next";
import DetailShimarLayout from "../../shimar/detail";

const Detail = ({
  initialState,
  initSelectedItem,
  initUnboxingVideos,
  alertMessage,
  updateAlertMessage
}) => {
  const { id, name } = useParams();
  const {t, i18n} = useTranslation();
  const isRtl = i18n.language === 'ur' || i18n.language === 'ar';
  useEffect(() => {
    console.log("useEffect called...");
    getDetails(id, name);
  }, [id, name]);

  const getDetails = (id, name) => {
    fetchData(`/api/detail/${id}/${name}`)
      .then((response) => {
        initSelectedItem(response);
        fetchData(
          `/YoutubeVideoController/search/${response?.item_name.replace(/\s+/g, '-').replace(/'/g, '')}/${response?.item_id}`
        )
          .then((result) => {
            initUnboxingVideos(result);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // useEffect(() => {
  //   if (alertMessage.message) {
  //     toast[alertMessage.type](alertMessage.message, {
  //       autoClose: 5000,
  //     });
  //   }
  // }, [alertMessage]);

  const addToWishlist = () => {
    try {
      const item_id = initialState?.item_id;
      if (!item_id) {
        throw new Error("Item ID not available.");
      }

      // Retrieve existing wishlist from localStorage
      const wishlist = JSON.parse(localStorage.getItem('wishlist')) || [];

      // Check if item already exists in the wishlist
      const existingItem = wishlist.find(item => item.item_id === item_id);
      if (existingItem) {
        throw new Error("Item already exists in the wishlist.");
      }

      // Add the new item to the wishlist
      wishlist.push(initialState);

      // Save the updated wishlist to localStorage
      localStorage.setItem('wishlist', JSON.stringify(wishlist));

      // Display success message
      updateAlertMessage({
        message: "Item added successfully to wishlist.",
        type: "success"
      });
    } catch (error) {
      console.log(error);
      updateAlertMessage({
        message: "An error occurred while adding the item to the wishlist.",
        type: "error"
      });
    }
  };

  const renderWishlistIcon = () => {
    const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];
    let isExists = false;
    _.forEach(storedWishlist, item => {
      if (item.item_id === initialState.item_id) {
        isExists = true;
      }
    });
    if (isExists) {
      return <i className="fa fa-heart" style={{ color: "green" }} title="Item already exists in wishlist."></i>;
    } else {
      return <i className="fa fa-heart-o"></i>;
    }
  }

  return (
    <>
      <Container className={`mb-3 mt-3 ${isRtl ? 'direction-rtl' : 'direction-ltr'}`}>
        <Row className="mb-5">
          <Col xs={12} md={5} className={`product-slider-container ${window.innerWidth <= 576 ? "p-2" : ""}`}>
            <Productslider />
          </Col>
          <Col xs={12} md={7}>
            <Row className="mt-2">
              <Col xs={10}>
                {initialState && initialState.item_name ? (
                  <h1 className={`mb-0 ${isRtl ? 'text-right' : 'text-left'}`}>{initialState.item_name}</h1>
                ) : (
                  <Placeholder animation="glow" className="d-flex">
                    <Placeholder style={{ width: "100%" }}>&nbsp;</Placeholder>
                  </Placeholder>
                )}
              </Col>
              <Col xs={2} className={`${isRtl ? 'text-left' : 'text-right'}`}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => addToWishlist()}
                >
                  {renderWishlistIcon()}
                </span>
              </Col>
            </Row>
            <Row>
              <Col className={`${isRtl ? 'text-right' : 'text-left'}`}>
                <Rating
                  count={initialState.ratings == "" ? 0 : parseInt(initialState.ratings)}
                  editing={false}
                  isTextShow={true}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Prices />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <Variants />
              </Col>
            </Row>
            {initialState && initialState.available_stock && initialState.available_stock !== null &&
              <Row>
                <Col>
                  <a href={`/order/${id}/${name}`} className="btn btn-warning btn-lg me-2" style={{ width: "30%" }}>Add to Cart</a>
                  <a href="/compare" className="btn btn-info btn-lg" style={{ width: "30%" }}>Compare</a>
                </Col>
              </Row>
            }
          </Col>
        </Row>
        {initialState.description && initialState.description !== "" && (
          <Row>
            <Col className="mt-3">
              <Card className="description-card">
                <Card.Header>
                  <h2 className={`mb-0 ${isRtl ? 'text-right': 'text-left'}`}>{t("txt_description")}</h2>
                </Card.Header>
                <Card.Body>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: atob(initialState.description),
                    }}
                    style={{ fontWeight: 400, textAlign: isRtl ? 'right' : 'left' }}
                  ></p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        <Row className="mt-3">
          <Col xs={12} md={8}>
            <Prominentspecs />
            <Specifications />
            <Reviews />
          </Col>
          <Col xs={12} md={4}>
            <Unboxingvideos />
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  initialState: state.home.selectedItem,
  alertMessage: state.home.alertMessage,
});

const dispatchStateToProps = (dispatch) => ({
  initSelectedItem: (data) => {
    dispatch({
      type: "INIT_SELECTED_ITEM",
      payload: data,
    });
  },
  initUnboxingVideos: (videos) => {
    dispatch({
      type: "INIT_UNBOXING_VIDEOS",
      payload: videos,
    });
  },
  updateAlertMessage: (msg) => {
    dispatch({
      type: "UPDATE_ALERT_MESSAGE",
      payload: msg,
    });
  },
});
export default connect(mapStateToProps, dispatchStateToProps)(Detail);
