import { Accordion, Placeholder } from "react-bootstrap";
import MobileCard from "./MobileCard";
import { Link } from "react-router-dom";

const ProductsByBrandShimarLayout = () => {
    return (
        <div className="container">
            <div className="row mt-3 mb-3">

                <div className="col-sm-12 col-md-3">
                    <Accordion
                        defaultActiveKey="0"
                        flush
                        activeKey={["0", "1", "2", "3", "4", "5", "6", "7", "8"]}
                    >
                        <Accordion.Item eventKey={"0"}>
                            <Accordion.Header>
                                <Placeholder xs={6} />
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="input-group">
                                    <ul className="icheck-list">
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                    </ul>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={"1"}>
                            <Accordion.Header><Placeholder xs={7} /></Accordion.Header>
                            <Accordion.Body>
                                <div className="input-group">
                                    <ul className="icheck-list">
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                    </ul>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={"2"}>
                            <Accordion.Header><Placeholder xs={7} /></Accordion.Header>
                            <Accordion.Body>
                                <div className="input-group">
                                    <ul className="icheck-list">
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                    </ul>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={"3"}>
                            <Accordion.Header><Placeholder xs={7} /></Accordion.Header>
                            <Accordion.Body>
                                <div className="input-group">
                                    <ul className="icheck-list">
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                    </ul>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey={"4"}>
                            <Accordion.Header><Placeholder xs={7} /></Accordion.Header>
                            <Accordion.Body>
                                <div className="input-group">
                                    <ul className="icheck-list">
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                    </ul>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={"4"}>
                            <Accordion.Header><Placeholder xs={7} /></Accordion.Header>
                            <Accordion.Body>
                                <div className="input-group">
                                    <ul className="icheck-list">
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                    </ul>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={"5"}>
                            <Accordion.Header><Placeholder xs={7} /></Accordion.Header>
                            <Accordion.Body>
                                <div className="input-group">
                                    <ul className="icheck-list">
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                    </ul>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={"6"}>
                            <Accordion.Header><Placeholder xs={7} /></Accordion.Header>
                            <Accordion.Body>
                                <div className="input-group">
                                    <ul className="icheck-list">
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                    </ul>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={"7"}>
                            <Accordion.Header><Placeholder xs={7} /></Accordion.Header>
                            <Accordion.Body>
                                <div className="input-group">
                                    <ul className="icheck-list">
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                        <li><Placeholder xs={12} /></li>
                                    </ul>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>

                <div className="col-sm-12 col-md-9">
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="mb-0"><Placeholder xs={12} style={{minHeight: 20, borderRadius: 15}}/></h2>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                            <Link to={"#"}><Placeholder xs={12} /></Link>
                            &nbsp;&nbsp;
                            <Link to={"#"}><Placeholder xs={12} className="mb-1" /></Link>
                        </div>
                    </div>

                    <div className="row" style={{ gap: 15 }}>
                        <MobileCard count={20} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductsByBrandShimarLayout;