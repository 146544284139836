import axios from "axios";
import React, { useState } from "react";
import {
  Container,
  Alert,
  Row,
  Col,
  Form,
  Button,
  Card,
} from "react-bootstrap";
import { BASE_URL } from "../../helpers/api";

const Contactus = () => {
  const [validated, setValidated] = useState(false);
  const [apiResponse, setApiResponse] = useState({});
  const [formData, setFormData] = useState({
    firstname:"",
    lastname:"",
    subject:"",
    message:""
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    axios.post(`${BASE_URL}/api/saveMessage`, formData, { headers: { 'Content-Type': 'application/json'}}).then(res => {
      if(res.status === 200) {
        setFormData({
          firstname: '',
          lastname: '',
          subject: '',
          message: ''
        });
        setApiResponse(res.data);
      }
    }).catch(err => {
      console.log(err);
    })
  };

  return (
      <Container style={window.innerWidth <= 576 ? {paddingLeft: 0, paddingRight: 0, marginTop: 15} : {paddingTop: 15}}>
        <Row className="mb-3">
          <Col lg={7} xl={6} xs={12}>
            {apiResponse && apiResponse.type && <Alert variant={apiResponse.type} dismissible>{apiResponse.message}</Alert>}
            <Card className="leave-a-message-card">
              <Card.Header>
                <h3 className="mb-0">Leave A Message</h3>
              </Card.Header>
              <Card.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="firstName">
                        <Form.Label>
                          First Name<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your first name"
                          required
                          value={formData.firstname}
                          onChange={(e) => setFormData({...formData, firstname: e.target.value})}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="lastName">
                        <Form.Label>
                          Last Name<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your last name"
                          required
                          value={formData.lastname}
                          onChange={(e) => setFormData({...formData, lastname: e.target.value})}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group controlId="subject">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Subject"
                          required
                          value={formData.subject}
                          onChange={(e) => setFormData({...formData, subject:e.target.value})}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group controlId="message">
                        <Form.Label>Your Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          placeholder="Enter your message..."
                          required
                          value={formData.message}
                          onChange={(e) => setFormData({...formData, message: e.target.value})}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="mt-3 mb-3">
                    <Button
                      type="submit"
                      className="btn btn-primary-dark-w px-5"
                    >
                      Send Message
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={5} xl={6} xs={12} style={window.innerWidth <= 576 ? {marginTop: 15} : {}}>
            <Card className="direct-contact-card">
              <Card.Header>
                <h3 className="mb-0">Our Address / Direct Contact</h3>
              </Card.Header>
              <Card.Body>
                <address className="text-lh-23">
                  Lahore, Punjab Pakistan
                  <div className="">Support(+92)343 8069098</div>
                  <div className="">
                    Email:{" "}
                    <a
                      className="text-blue text-decoration-on"
                      href="mailto:pakmallz786@gmail.com"
                    >
                      pakmallz786@gmail.com
                    </a>
                  </div>
                </address>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
  );
};

export default Contactus;
