import { Placeholder } from "react-bootstrap";

const MobileCard = ({ count }) => {
  const links = Array.from({ length: count }, (_, index) => index);
  const isMobile = window.innerWidth <= 576;

  return (
    <div
      className={`row${isMobile ? "" : " mt-3 mb-3 d-flex flex-row flex-wrap"}`}
      style={!isMobile ? { gap: 15 } : {}}
    >
      {links &&
        links.length > 0 &&
        links.map((item, key) => (
          <div
            className={`p-2  ${isMobile ? "col-6" : "col"}`}
            style={{
              background: "white",
              borderRadius: 0,
              paddingTop: 15,
              paddingBottom: 15,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              jusitfyContent: "center"
            }}
            key={key}
          >
            <Placeholder
              animation="glow"
              style={{
                width: "100%",
                textAlign: "center",
                padding: "0px 15px",
              }}
            >
              <Placeholder
                xs={12}
                style={{ minHeight: 140, width: 77, borderRadius: 15 }}
                className="mb-1"
              />
              <Placeholder xs={12} className="mb-1" style={{ minWidth: 150 }} />
              <Placeholder xs={12} className="mb-1" />
              <Placeholder xs={12} className="mb-1" />
              <Placeholder xs={8} className="mb-1" />
            </Placeholder>
          </div>
        ))}
    </div>
  );
};

export default MobileCard;