import { connect } from "react-redux";
import AppRoutes from "./routes";

const App = ({initialState}) => {
  return (
    <>
      <AppRoutes />
    </>
  );
}

const mapStateToProps = (state) => ({
  initialState: state.home
});

const dispatchStateToProps = (dispatch) => ({

});
export default connect(mapStateToProps, dispatchStateToProps)(App);
