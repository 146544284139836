import { combineReducers } from 'redux';
import homeReducer from "./homeReducer";

// Combine multiple reducers if needed
const rootReducer = combineReducers({
  home: homeReducer,
  // Add other reducers here
});

export default rootReducer;
