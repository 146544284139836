export function formatDate(dateString) {
  const date = new Date(dateString);

  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
    timeZone: "UTC",
  };

  const formattedDate = date.toLocaleString("en-US", options);
  const timePart = formattedDate.slice(-2); // Extract the last 2 characters

  const formattedTime = formattedDate.replace(timePart, timePart.toUpperCase());
  return formattedTime;
}

export function generateRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
}

export function renderPrice(item, selectedCurrency, boldCurrency = false) {
  if (item.price_rs === "-1" || item.price_rs === -1) {
    if (item.expected_price === "0" || item.expected_price === 0) {
      return <div className="product-price">NaN</div>;
    } else {
      return (
        <div className="product-price">
          {item.expected_price ? (
            boldCurrency ? (
              <div>
                <strong>
                  {parseFloat(item.expected_price).toLocaleString('en-US', {
                    style: 'currency',
                    currency: selectedCurrency && selectedCurrency.code ? selectedCurrency.code : 'pkr', // Provide the currency code
                  })}
                </strong>
                <span className="price-expected-keyword">(Exp)</span>
              </div>
            ) : (
              <>
                {parseFloat(item.expected_price).toLocaleString('en-US', {
                  style: 'currency',
                  currency: selectedCurrency && selectedCurrency.code  ? selectedCurrency.code : 'pkr', // Provide the currency code
                })}
                <span className="price-expected-keyword">(Exp)</span>
              </>
            )
          ) : (
            <small className="text-primary">Coming Soon</small>
          )}
        </div>
      );
    }
  } else if (item.price_rs === -2 || item.price_rs === "-2") {
    return <small className="text-danger">Disconnected</small>;
  } else {
    return (
      <div className="product-price">
        {item.price_rs ? (
          boldCurrency ? (
            <strong>
              {parseFloat(item.price_rs).toLocaleString('en-US', {
                style: 'currency',
                currency: selectedCurrency && selectedCurrency.code  ? selectedCurrency.code : 'pkr', // Provide the currency code
              })}
            </strong>
          ) : (
            parseFloat(item.price_rs).toLocaleString('en-US', {
              style: 'currency',
              currency: selectedCurrency && selectedCurrency.code  ? selectedCurrency.code : 'pkr', // Provide the currency code
            })
          )
        ) : (
          <small className="text-danger">Not Found</small>
        )}
      </div>
    );
  }
}

export function removeHtmlTags(str) {
  return str.replace(/<[^>]+>/g, "");
}

export const handleImageError = (e, screen) => {
  console.log("screen",screen);
  if (screen !== undefined) {
    e.target.src = "/smartphone.png";
    e.target.style.width = "auto";
    e.target.style.height = "auto";
  } else {
    e.target.src = "/smartphone.png";
  }
};
