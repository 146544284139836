import { Container } from "react-bootstrap";

const Privacypolicy = () => {
  return (
    <Container className="mt-5">
      <div className="mb-5 col-lg-12 col-xl-12 mb-8 mb-lg-0 privacy-sec">
        <h3 className="privacy-heading">Privacy Policy</h3>
        <p>
          Pakmallz collects, uses, maintains and discloses information
          collected from users (each, a "User") of the www.pakmallz.com
          website ("Site"). This privacy policy applies to the Site and all
          products by Pakmallz.
        </p>
        <h4>Personal identification information</h4>
        <p>
          We may collect personal identification information from Users name,
          email address and their reviews about the product to make batter
          rating for product not other data we collect of user.
        </p>
        <h4>Non-personal identification information</h4>
        <p>
          We may collect non-personal identification information about Users
          whenever they interact with our Site. Non-personal identification
          information may include the browser name, the type of computer and
          technical information about Users means of connection to our Site,
          such as the operating system and the Internet service providers
          utilized and other similar information.
        </p>
        <h4>Log Files</h4>
        <p>
          Like many other Web sites, www.pakmallz.com makes use of log files.
          The information inside the log files includes internet protocol ( IP
          ) addresses, type of browser, Internet Service Provider ( ISP ),
          date/time stamp, referring/exit pages, and number of clicks to
          analyze trends, administer the site, track users movement around the
          site, and gather demographic information. IP addresses, and other
          such information are not linked to any information that is
          personally identifiable.
        </p>
        <h4>Cookies and Web Beacons</h4>
        <p>www.pakmallz.com does not use cookies.</p>
        <h4>Sharing your personal information</h4>
        <p>
          We do not sell, trade, or rent Users personal identification
          information to others. We may share generic aggregated demographic
          information not linked to any personal identification information
          regarding visitors and users with our business partners, trusted
          affiliates and advertisers for the purposes outlined above.
        </p>
        <h4>Changes to this privacy policy</h4>
        <p>
          Pakmallz has the discretion to update this privacy policy at any
          time. When we do, we will revise the updated date at the bottom of
          this page. We encourage Users to frequently check this page for any
          changes to stay informed about how we are helping to protect the
          personal information we collect. You acknowledge and agree that it
          is your responsibility to review this privacy policy periodically
          and become aware of modifications.
        </p>
        <h4>Your acceptance of these terms</h4>
        <p>
          By using this Site, you signify your acceptance of this policy. If
          you do not agree to this policy, please do not use our Site. Your
          continued use of the Site following the posting of changes to this
          policy will be deemed your acceptance of those changes.
        </p>
        <h4>DoubleClick DART Cookie</h4>
        <ul className="list-group border-0 mb-4">
          <li className="list-group-item border-0">
            Google, as a third party vendor, uses cookies to serve ads on
            www.pakmallz.com.
          </li>
          <li className="list-group-item border-0">
            Google's use of the DART cookie enables it to serve ads to your
            users based on their visit to www.pakmallz.com and other sites on
            the Internet.
          </li>
          <li className="list-group-item border-0">
            Users may opt out of the use of the DART cookie by visiting the
            Google ad and content network privacy policy at the following URL
            - http://www.google.com/privacy_ads.html
          </li>
        </ul>
        <p>
          Some of our advertising partners may use cookies and web beacons on
          our site. Our advertising partners include .......
          <br />
          Google Adsense
          <br />
          These third-party ad servers or ad networks use technology to the
          advertisements and links that appear on www.pakmallz.com send
          directly to your browsers. They automatically receive your IP
          address when this occurs. Other technologies ( such as cookies,
          JavaScript, or Web Beacons ) may also be used by the third-party ad
          networks to measure the effectiveness of their advertisements and /
          or to personalize the advertising content that you see.
          <br /> www.pakmallz.com has no access to or control over these
          cookies that are used by third-party advertisers.
          <br />
          If you wish to disable cookies, you may do so through your
          individual browser options. More detailed information about cookie
          management with specific web browsers can be found at the browsers'
          respective websites.
        </p>
        <h4>Contact us</h4>
        <p>
          If you require any more information or have any questions about our
          privacy policy, please feel free to contact us by email at
          pakmallz786@gmail.com.
        </p>
      </div>
    </Container>
  );
};

export default Privacypolicy;
