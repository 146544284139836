import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Checkbox } from "react-icheck";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const Filters = ({ selectedCurrency, filters, addIntoFilterModal, clearFilterModal, filterModal, setSelectedPageNumber }) => {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState(["0", "1", "2", "3", "4", "5", "6", "7", "8"]);
  const [brandShowMoreLimit, setBrandShowMoreLimit] = useState(10);
  const toggleAccordionItem = (eventKey) => {
    if (activeKey.includes(eventKey)) {
      setActiveKey(activeKey.filter((key) => key !== eventKey));
    } else {
      setActiveKey([...activeKey, eventKey]);
    }
  };

  const onCheckboxChange = (e, obj) => {
    if (e.target.checked) {
      addIntoFilterModal(obj);
    } else {
      clearFilterModal();
    }
  }

  const onBrandCheckboxChange = (e, obj) => {
    if(e.target.checked) {
      setSelectedPageNumber(1);
      navigate(`/mobiles/${obj.title}/${obj.id}/1`);
      let newObj = obj;
      newObj.id = 'brand_'+ newObj.id;
      addIntoFilterModal(newObj);
    } else {
      clearFilterModal();
    }
  }

  const handleMoreClick = () => {
    setBrandShowMoreLimit(brandShowMoreLimit + 10);
  }

  return (
    <Accordion
      defaultActiveKey="0"
      flush
      activeKey={activeKey}
      onSelect={toggleAccordionItem}
    >
      {filters && filters.length > 0 && filters.map((filter, key) => (
        <Accordion.Item eventKey={key.toString()}>
          <Accordion.Header>{filter.title}</Accordion.Header>
          <Accordion.Body>
            <div className="input-group" style={{maxHeight: 350, overflowY: 'scroll'}}>
              <ul className="icheck-list">
                {filter.title === 'BRANDS' && filter.subCategories && filter.subCategories.length > 0 ? (
                  <>
                    {filter.subCategories.slice(0, brandShowMoreLimit).map((subCat, key2) => (
                      <li key={key2}>
                        <Checkbox
                          checkboxClass="icheckbox_flat-blue"
                          increaseArea="15%"
                          checked={filterModal && Object.keys(filterModal).length > 0 && filterModal.id === 'brand_' + subCat.id}
                          label={` ${subCat.title}`}
                          onChange={(e) => onBrandCheckboxChange(e, subCat)}
                        />
                      </li>
                    ))}
                    {filter.subCategories.length > 10 && (
                      <li>
                        <Link to={"#"} onClick={() => handleMoreClick()} className="ml-1">More...</Link>
                      </li>
                    )}
                  </>
                ) : (
                  filter.subCategories && filter.subCategories.length > 0 && filter.subCategories.map((subCat, key2) => (
                    <li key={key2}>
                      <Checkbox
                        checkboxClass="icheckbox_flat-blue"
                        increaseArea="15%"
                        checked={filterModal && Object.keys(filterModal).length > 0 && filterModal.id === subCat.id}
                        label={` ${subCat.title}`}
                        onChange={(e) => onCheckboxChange(e, subCat)}
                      />
                    </li>
                  ))
                )}
              </ul>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const mapStateToProps = (state) => ({
  selectedCurrency: state.home.selected_currency,
  brands: state.home.brands,
  filterModal: state.home.filterModal
});

const dispatchStateToProps = (dispatch) => ({
  addIntoFilterModal: (obj) => {
    dispatch({
      type: "ADD_ITEM_TO_FILTER_MODAL",
      payload: obj
    });
  },
  clearFilterModal: () => {
    dispatch({
      type: "RESET_FILTER_MODAL",
      payload: ''
    });
  }
});

export default connect(mapStateToProps, dispatchStateToProps)(Filters);
