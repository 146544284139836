import React from 'react';
import { Form, FormControl, Dropdown } from 'react-bootstrap';
import { handleImageError } from '../../helpers/common';
import { useTranslation } from 'react-i18next';

const SearchForm = ({ inputSearch, setInputSearch, searchResults, handleOnSearchItemSelect }) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ur' || i18n.language === 'ar';
  return (
    <Form className="app-search d-md-block d-lg-block">
      <FormControl
        type="search"
        style={{ direction: isRtl ? 'rtl' : 'ltr' }}
        placeholder={t("txt_search") + "..."}
        value={inputSearch}
        onChange={(e) => setInputSearch(e.target.value)}
      />
      {searchResults && searchResults.length > 0 && (
        <Dropdown show={true} className="search-input-results-dropdown">
          <Dropdown.Menu>
            {searchResults && searchResults.length > 0 && searchResults.map((result, _key) => (
              <Dropdown.Item
                key={result.id + "_" + _key}
                onClick={() => handleOnSearchItemSelect(result)}
              >
                <div className="d-flex flex-row align-items-center">
                  <img
                    src={result.thumbnail}
                    alt="img"
                    height={50}
                    onError={handleImageError}
                  />
                  <span style={{ marginLeft: 5 }}>{result.name}</span>
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Form>
  );
};

export default SearchForm;
