import { useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { Image } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../helpers/api";
import { useTranslation } from "react-i18next";

const LeftSideBarMenu = ({ categories, isLeftSideMenuShow, toggleLeftSideMenu, brands }) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ur' || i18n.language === 'ar';
  const activeKeyList = Array.from({ length: categories }, (_, index) => String(index));
  const [activeKey, setActiveKey] = useState([activeKeyList]);

  const toggleAccordionItem = (eventKey) => {
    if (activeKey.includes(eventKey)) {
      setActiveKey(activeKey.filter((key) => key !== eventKey));
    } else {
      setActiveKey([...activeKey, eventKey]);
    }
  };

  return (
    <>
      <Offcanvas
        show={isLeftSideMenuShow}
        onHide={() => toggleLeftSideMenu(false)}
        style={{ zIndex: 100000000000 }}
        placement={isRtl ? "end" : "start"}
      >
        <Offcanvas.Body>
          <div className="sb-user-box">
            <button type="button" className="btn-close" aria-label="Close" onClick={() => toggleLeftSideMenu(false)} style={{ right: 25, position: "absolute", color: "white" }}></button>
            <ul>
              <li>
                <a className="navbar-brand" href="/" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', marginBottom: 5 }}>
                  <i className="mdi mdi-cart" style={{ fontSize: '2.5em' }}></i>
                  <b style={{ fontSize: 35, fontFamily: 'fantasy', letterSpacing: 2 }}>
                    Pakmallz
                    {/* <img src="../assets/images/logo-icon.png" alt="homepage" className="dark-logo" />
                <img src="../assets/images/logo-light-icon.png" alt="homepage" className="light-logo" /> */}
                  </b>
                </a>
              </li>
              <li>
                <a className="btn waves-effect waves-light btn-outline-secondary" style={{ width: '50%' }} href={`${BASE_URL}/login`}>Login</a>
              </li>
              <li>
                <Link to={"#"} className="text-white font-14">Track my order</Link>
              </li>
              <li>
                <Link to={"#"} className="text-white font-14">Launch a complaints</Link>
              </li>
            </ul>
          </div>
          <div className={`p-4 ${isRtl ? 'direction-rtl' : 'direction-ltr'}`}>
            <p className={`text-muted mb-0 font-weight-700 ${isRtl ? 'text-right' : 'text-left'}`}>{t("txt_categories")}</p>

            <Accordion
              defaultActiveKey="0"
              flush
              activeKey={activeKey}
              onSelect={toggleAccordionItem}
              className="sidebar-offcanvas"
            >
              {categories && categories.length > 0 && categories.map((category, mkey) => (
                <Accordion.Item eventKey={mkey} className="my-accordion-item" key={mkey}>
                  <Accordion.Header>
                    <span>
                      <Image src={`${BASE_URL}/${category.thumbnail}`} width={30} style={isRtl ? { marginLeft: 15 } : { marginRight: 15 }} />
                      <Link to={"#"} style={{ color: 'black' }}>{category.name}</Link>
                    </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="input-group">
                      <ul className={`icheck-list ${isRtl ? 'text-right pr-0' : 'text-left'}`}>
                        {category.subCategories && category.subCategories.length > 0 && category.subCategories.map((subCat, key2) => (
                          <li key={key2}><Link to={`/${category.slug}/${subCat.name.replace(/\s+/g, '-').replace(/'/g, '')}/${subCat.id}/${category.id}`} style={{ color: 'black', fontWeight: 600, lineHeight: '25px' }} onClick={() => toggleLeftSideMenu(false)}>{subCat.name}</Link></li>
                        ))}
                        {/* ${category.id}/${subCat.id}/${subCat.name} */}
                      </ul>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
            <p className={`text-muted mb-0 font-weight-700 ${isRtl ? 'text-right' : 'text-left'}`}>{t("txt_popular_list")}</p>
            <p className={`text-muted mb-0 font-weight-700 ${isRtl ? 'text-right' : 'text-left'}`}>{t("txt_main_navigation")}</p>
            <ul className={`main-navigation-list ${isRtl ? 'text-right' : 'text-left'}`}>
              <li><Link to={"/about"} onClick={() => toggleLeftSideMenu(false)}>{t("txt_about")}</Link></li>
              <li><Link to={"/faq"} onClick={() => toggleLeftSideMenu(false)}>{t("txt_faq")}</Link></li>
              <li><Link to={"/wishlist"} onClick={() => toggleLeftSideMenu(false)}>{t("txt_wishlist")}</Link></li>
              <li><Link to={"/reviews"} onClick={() => toggleLeftSideMenu(false)}>{t("txt_reviews")}</Link></li>
              <li><Link to={"/contact-us"} onClick={() => toggleLeftSideMenu(false)}>{t("txt_contact")}</Link></li>
              <li><Link to={"/privacy-policy"} onClick={() => toggleLeftSideMenu(false)}>{t("txt_privacy")}</Link></li>
              <li><a href={`${BASE_URL}/sitemap.xml`}>{t("txt_sitemap")}</a></li>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLeftSideMenuShow: state.home.isLeftSideMenuShow,
  categories: state.home.categories,
  brands: state.home.brands
});
const dispatchStateToProps = (dispatch) => ({
  toggleLeftSideMenu: (payload) => {
    dispatch({
      type: "TOGGLE_LEFT_SIDEBAR_MENU",
      payload: payload,
    });
  },
});

export default connect(mapStateToProps, dispatchStateToProps)(LeftSideBarMenu);
