import { fetchData, postData } from "../../helpers/api";

// Initial state for the HomePage
const initialState = {
  isPageLoaded: false,
  isHomeCall: true,
  brands: [],
  currencies: [],
  categories: [],
  latest: [],
  up_comming: [],
  up_coming: [],
  trending: [],
  selected_currency: {},
  filters: [],
  filterModal: {},
  selectedItem: {
    attachments: [],
    canonical_url: "",
    generals: [],
    hasSecifications: false,
    hits: 0,
    item_id: null,
    item_name: "",
    prices: [],
    prominents: [],
    reviews: [],
    descriptoin: "",
    ratings: 0,
    unboxingVideos: [],
    variants: {
      storages: [],
      colors: []
    },
    threshold_value: null,
    available_stock: null
  },
  alertMessage: {
    message: "",
    type: "",
  },
  pagination: {
    totalCount: 0,
    currentPage: 1,
  },
  isMobileSearchFormVisible: false,
  searchResults: [],
  inputSearch: "",
  showDrawer: false,
  isRightSideMenuShow: false,
  isLeftSideMenuShow: false,
  dashboard: {
    sidebar: true
  },
  selectedBrandId: null,
  selectedBrandName: null,
  mobileScreenView: 'grid',
  selectedColorVariantId: 0,
  selectedStorageVariantId: 0,
  slider: [],
  selectedItemForCompare: {}
};

// Reducer for the HomePage
function homeReducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case "INIT_GUEST_HOME_PAGE":
      return {
        ...state,
        ...payload,
      };
    case "INIT_SELECTED_ITEM":
      return {
        ...state,
        selectedItem: payload,
      };
    case "UPDATE_SELECTED_ITEM_FOR_COMPARE":
      return {
        ...state,
        selectedItemForCompare: payload,
      };
    case "INIT_UNBOXING_VIDEOS":
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          unboxingVideos: payload,
        },
      };
    case "UPDATE_REVIEWS":
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          reviews: [...state.selectedItem.reviews, payload],
        },
      };
    case "UPDATE_ALERT_MESSAGE":
      return {
        ...state,
        alertMessage: payload,
      };
    case "UPDATE_TOTAL_COUNT":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalCount: payload,
        },
      };
    case "UPDATE_CURRENT_PAGE":
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: payload,
        },
      };
    case "UPDATE_IS_PAGE_LOADED":
      return {
        ...state,
        isPageLoaded: payload,
      };
    case "UPDATE_IS_HOME_CALL":
      return {
        ...state,
        isHomeCall: payload,
      };
    case "INIT_HEADER":
      return {
        ...state,
        brands: payload.brands,
        currencies: payload.currencies,
        categories: payload.categories,
        selected_currency: payload.selected_currency,
      };
    case "INIT_SELECTED_ITEM_INITIAL_STATE":
      return {
        ...state,
        selectedItem: {
          ...state.selectedItem,
          attachments: [],
          canonical_url: "",
          generals: [],
          hasSecifications: false,
          hits: 0,
          item_id: null,
          item_name: "",
          prices: [],
          prominents: [],
          reviews: [],
          descriptoin: "",
          ratings: 0,
          unboxingVideos: [],
        },
      };
    case "UPDATE_IS_MOBILE_SEARCH_FORM_VISIBLE":
      return {
        ...state,
        isMobileSearchFormVisible: payload,
      };
    case "UPDATE_SEARCH_RESULTS":
      return {
        ...state,
        searchResults: payload,
      };
    case "UPDATE_INPUT_SEARCH":
      return {
        ...state,
        inputSearch: payload,
      };
    case "TOGGLE_DRAWER":
      return {
        ...state,
        showDrawer: payload,
      };
    case "UPDATE_SELECTED_CURRENCY":
      fetchData(`/currency/set/${payload.id}`).then(response => {
        console.log(response);
      }).catch(error => {
        console.log(error);
      })
      return {
        ...state,
        selected_currency: payload
      }
    case "TOGGLE_SETTINGS_PANEL":
      return {
        ...state,
        isRightSideMenuShow: payload
      }
    case "TOGGLE_LEFT_SIDEBAR_MENU":
      return {
        ...state,
        isLeftSideMenuShow: payload
      }
    case "APPEND_SEARCH_RESULT_INTO_BRANDS":
      const newBrandData = payload;
      const updatedBrands = [...state.brands];

      const uniqueNewBrandsData = newBrandData.filter((newBrand) => {
        return !updatedBrands.some((existingBrand) => existingBrand.id === newBrand.id);
      });

      updatedBrands.unshift(...uniqueNewBrandsData);
      console.log("updated", uniqueNewBrandsData)
      return {
        ...state,
        brands: updatedBrands,
      };
    case "DASHBOARD_SIDEBAR_TOGGLE":
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          sidebar: !state.dashboard.sidebar
        }
      }
    case "UPDATE_BRAND_ID":
      return {
        ...state,
        selectedBrandId: payload
      }
    case "UPDATE_BRAND_NAME":
      return {
        ...state,
        selectedBrandName: payload
      }
    case "UPDATE_MOBILE_TABULER_VIEW":
      return {
        ...state,
        mobileScreenView: payload
      }
    case "UPDATE_SELECTED_COLOR_VARIANT_ID":
      return {
        ...state,
        selectedColorVariantId: payload
      }
    case "UPDATE_SELECTED_STORAGE_VARIANT_ID":
      return {
        ...state,
        selectedStorageVariantId: payload
      }
    case "ADD_ITEM_TO_FILTER_MODAL":
      return {
        ...state,
        filterModal: payload,
      };
    case "RESET_FILTER_MODAL":
      return {
        ...state,
        filterModal: {},
      };
    default:
      return state;
  }
}

export default homeReducer;
