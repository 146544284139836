import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import LandingLayout from "../components/Layouts/LandingLayout";
import Home from "../components/landing/home";
import Detail from "../components/landing/detail";
import Wishlist from "../components/landing/wishlist";
import Contactus from "../components/landing/contactus";
import Privacypolicy from "../components/landing/privacypolicy";
import Compare from "../components/landing/compare";
import FAQs from "../components/landing/faq";
import InstallmentPlan from "../components/landing/installment-plan";
import About from "../components/landing/about";
import Order from "../components/landing/order";
import ProductsByBrand from "../components/brands/products.by.brand";

const AppRoutes = () => {
    return (
        <>
            <Router>
                <Routes>
                    <Route element={<LandingLayout />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/product/detail/:id/:name" element={<Detail />} />
                        <Route path="/:slug/:brandName/:brandId/:catId" element={<ProductsByBrand />} />
                        <Route path="/wishlist" element={<Wishlist />} />
                        <Route path="/faq" element={<FAQs />} />
                        <Route path="/installment-plans" element={<InstallmentPlan />} />
                        <Route path="/contact-us" element={<Contactus />} />
                        <Route path="/privacy-policy" element={<Privacypolicy />} />
                        <Route path="/compare/:id" element={<Compare />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/order/:id/:name" element={<Order />} />
                    </Route>
                </Routes>
            </Router>
        </>
    );
}


export default AppRoutes;