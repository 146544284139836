import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas
} from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BASE_URL, fetchData, postData } from "../../helpers/api";
import SearchForm from "./searchForm";
import { useTranslation } from "react-i18next";

const Header = ({
  updateCurrentPage,
  initHeader,
  initSelectedItemToInitialState,
  searchResults,
  setSearchResults,
  inputSearch,
  setInputSearch,
  isPageLoaded,
  appendSearchResultIntoBrands,
  setIsHomeCall,
  toggleLeftSideMenu,
}) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ur' || i18n.language === 'ar';
  const navigate = useNavigate();
  const [filterBrand, setFilterBrand] = useState("");
  const [searchFormVisible, setSearchFormVisible] = useState(false);
  const [brandSearchResults, setBrandSearchResults] = useState([]);
  const [showMessageMenu, setShowMessageMenu] = useState(false);
  let expand = false;

  const handleBrandFilterChange = (event) => {
    setFilterBrand(event.target.value);
  };

  const handleOnBrandItemClick = (brand) => {
    updateCurrentPage(1);
    setFilterBrand("");
    setBrandSearchResults([]);
    navigate(`/mobiles/${brand.id}`);
  };

  useEffect(() => {
    fetchData("/api/init_header")
      .then((response) => {
        initHeader(response);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }, []);

  useEffect(() => {
    if (isPageLoaded) {
      if (inputSearch && inputSearch.length > 3) {
        postData("/api/search", { type: 'mobile', keyword: inputSearch })
          .then((response) => {
            setSearchResults(response);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
      } else {
        setSearchResults([]);
      }
    }
  }, [inputSearch]);

  const handleOnSearchItemSelect = (item) => {
    initSelectedItemToInitialState();
    setSearchResults([]);
    setInputSearch("");
    navigate(`/product/detail/${item.id}/${item.name}`);
  };

  const navigateToHome = () => {
    setIsHomeCall(true);
  };

  useEffect(() => {
    // Add event listener to track window size changes
    const handleResize = () => {
      if (window.matchMedia("(min-width: 768px)").matches) {
        // Render the search form for desktop view
        setSearchFormVisible(true);
      } else {
        // Hide the search form for mobile view
        setSearchFormVisible(false);
      }
    };

    // Initial check for the window size
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isPageLoaded) {
      postData("/api/search", { type: 'brand', keyword: filterBrand })
        .then((response) => {
          appendSearchResultIntoBrands(response);
          //setBrandSearchResults(response);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    }
  }, [filterBrand]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setShowMessageMenu(false);
  };

  return (
    <div className="mini-sidebar">
      <header class={`topbar ${isRtl ? 'direction-rtl' : 'direction-ltr'}`}>
        <nav class="navbar top-navbar navbar-expand-md navbar-dark">
          {/* <div class="navbar-header">
            <a class="navbar-brand" href="/">
              <b>
                <img src="output-onlinegiftools-old.gif" alt="homepage" class="dark-logo" style={{width: 40, height: 40}}/>
                <img src="../assets/images/logo-light-icon.png" alt="homepage" class="light-logo" />
              </b>
              <span style={{display: 'none'}}>
                <img src="../assets/images/logo-text.png" alt="homepage" class="dark-logo" />
                <img src="../assets/images/logo-light-text.png" class="light-logo" alt="homepage" />
              </span>
            </a>
          </div> */}
          <div class="navbar-collapse">
            <ul class={`navbar-nav ${isRtl ? 'pr-0' : 'me-auto'}`}>
              <li class="nav-item"> <a class="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" href="javascript:void(0);" onClick={() => toggleLeftSideMenu(true)}><i class="ti-close ti-menu"></i></a> </li>
              <li class="nav-item"> <a class="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark" href="javascript:void(0);" onClick={() => toggleLeftSideMenu(true)}><i class="icon-menu ti-menu"></i></a> </li>
              <li class="nav-item">
                <SearchForm inputSearch={inputSearch} setInputSearch={setInputSearch} searchResults={searchResults} handleOnSearchItemSelect={handleOnSearchItemSelect} />
              </li>
            </ul>
            <ul class={`navbar-nav my-lg-0 ${isRtl ? 'me-auto ml-2' : 'me-2'}`} style={{ display: "flex", alignItems: "center" }}>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0);" onClick={() => setShowMessageMenu(!showMessageMenu)} id="2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="fa fa-language"></i>
                  <div class="notify">
                    <span class="heartbit"></span>
                    <span class="point"></span>
                  </div>
                </a>
                <div class={`dropdown-menu mailbox dropdown-menu-end animated bounceInDown ${showMessageMenu ? 'show' : ''}`} aria-labelledby="2" style={{ width: 'auto' }}>
                  <ul>
                    <li><a href="javascript:void(0)" className={`dropdown-item ${isRtl ? 'text-right':'text-left'}`} onClick={() => changeLanguage('en')}>English</a></li>
                    <li><a href="javascript:void(0)" className={`dropdown-item ${isRtl ? 'text-right':'text-left'}`} onClick={() => changeLanguage('ur')}>Urdu</a></li>
                    <li><a href="javascript:void(0)" className={`dropdown-item ${isRtl ? 'text-right':'text-left'}`} onClick={() => changeLanguage('ar')}>Arabic</a></li>
                    <li><a href="javascript:void(0)" className={`dropdown-item ${isRtl ? 'text-right':'text-left'}`} onClick={() => changeLanguage('sp')}>Spanish</a></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item d-flex align-items-center">
                <a className="btn waves-effect waves-light btn-light bg-transparent text-white" href={`${BASE_URL}/login`}>{t('txt_login')}</a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      {/* <header className={`topbar ${isRtl ? 'direction-rtl' : 'direction-ltr'}`}>
        <nav className="navbar top-navbar navbar-expand-md navbar-dark container bg-transparent">
          <div className="navbar-header">
            <a className="navbar-brand" href="/" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <i className="mdi mdi-cart" style={{ fontSize: '2.5em' }}></i>
              <b style={{ fontSize: 35, fontFamily: 'fantasy', letterSpacing: 2 }} className="logo-text">Pakmallz</b>
            </a>
          </div>
          <div className="navbar-collapse">
            <ul className={`navbar-nav ${isRtl ? '' : 'me-auto'}`}>
              <li className="nav-item"> <a className="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" onClick={() => toggleLeftSideMenu(true)}><i className="ti-menu"></i></a> </li>
              <li className="nav-item"> <a className="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark" onClick={() => toggleLeftSideMenu(true)}><i className="icon-menu"></i></a> </li>
              <li className="nav-item">
                <SearchForm inputSearch={inputSearch} setInputSearch={setInputSearch} searchResults={searchResults} handleOnSearchItemSelect={handleOnSearchItemSelect} />
              </li>
            </ul>
            <ul className={`navbar-nav my-lg-0 nav-auth-buttons d-flex align-items-center ${isRtl ? 'me-auto' : ''}`}>
              <li className="nav-item me-2">
                <Nav>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title={<span className="btn waves-effect waves-light btn-light bg-transparent"><i className='fa fa-language text-white'></i></span>}
                    menuVariant="light"
                  >
                    <NavDropdown.Item href="javascript:void(0);" onClick={() => changeLanguage('en')}>English</NavDropdown.Item>
                    <NavDropdown.Item href="javascript:void(0);" onClick={() => changeLanguage('ur')}>Urdu</NavDropdown.Item>
                    <NavDropdown.Item href="javascript:void(0);" onClick={() => changeLanguage('ar')}>Arabic</NavDropdown.Item>
                    <NavDropdown.Item href="javascript:void(0);" onClick={() => changeLanguage('sp')}>Spanish</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </li>
              <li className="nav-item d-flex align-items-center">
                <a className="btn waves-effect waves-light btn-light bg-transparent text-white" href={`${BASE_URL}/login`}>{t('txt_login')}</a>
              </li>
            </ul>
          </div>
        </nav>
      </header> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  initialState: state.home,
  isMobileSearchFormVisible: state.home.isMobileSearchFormVisible,
  searchResults: state.home.searchResults,
  inputSearch: state.home.inputSearch,
  isPageLoaded: state.home.isPageLoaded,
});

const dispatchStateToProps = (dispatch) => ({
  updateCurrentPage: (num) => {
    dispatch({
      type: "UPDATE_CURRENT_PAGE",
      payload: num,
    });
  },
  initHeader: (data) => {
    dispatch({
      type: "INIT_HEADER",
      payload: data,
    });
  },
  initSelectedItemToInitialState: () => {
    dispatch({
      type: "INIT_SELECTED_ITEM_INITIAL_STATE",
      payload: null,
    });
  },
  toggleMobileSearchForm: (payload) => {
    dispatch({
      type: "UPDATE_IS_MOBILE_SEARCH_FORM_VISIBLE",
      payload: payload,
    });
  },
  setSearchResults: (payload) => {
    dispatch({
      type: "UPDATE_SEARCH_RESULTS",
      payload: payload,
    });
  },
  setInputSearch: (input) => {
    dispatch({
      type: "UPDATE_INPUT_SEARCH",
      payload: input,
    });
  },
  toggleDrawer: (payload) => {
    dispatch({
      type: "TOGGLE_DRAWER",
      payload: payload,
    });
  },
  setSelectedCurrency: (payload) => {
    dispatch({
      type: "UPDATE_SELECTED_CURRENCY",
      payload: payload,
    });
  },
  toggleRightSideBarMenu: (payload) => {
    dispatch({
      type: "TOGGLE_SETTINGS_PANEL",
      payload: payload,
    });
  },
  appendSearchResultIntoBrands: (payload) => {
    dispatch({
      type: "APPEND_SEARCH_RESULT_INTO_BRANDS",
      payload: payload,
    });
  },
  setIsHomeCall: (value) => {
    dispatch({
      type: "UPDATE_IS_HOME_CALL",
      payload: value,
    });
  },
  toggleLeftSideMenu: (payload) => {
    dispatch({
      type: "TOGGLE_LEFT_SIDEBAR_MENU",
      payload: payload,
    });
  },
});

export default connect(mapStateToProps, dispatchStateToProps)(Header);
