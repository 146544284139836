import { Outlet } from "react-router-dom";
import Header from "../includes/header";
import Footer from "../includes/footer";
import AnnouncementBar from "../includes/AnnouncementBar";
import LeftSideBarMenu from "../includes/LeftSideBarMenu";
import MyToast from "./../../../src/toast";

const LandingLayout = () => {
    return (
        <div className="custom-bg-clr">
            <MyToast />
            {/* <AnnouncementBar /> */}
            <LeftSideBarMenu />
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
}

export default LandingLayout;