import { Card, Placeholder } from "react-bootstrap";
import { connect } from "react-redux";
import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import { useTranslation } from "react-i18next";

const Unboxingvideos = ({ videos }) => {
  const {t, i18n} = useTranslation();
  const isRtl = i18n.language === 'ur' || i18n.language === 'ar';
  const placeholderCount = 10;

  return (
    <Card className={`unboxing-videos-card ${isRtl ? 'direction-rtl' : 'direction-ltr'}`}>
      <Card.Header>
        <h3 className={`mb-0 ${isRtl ? 'text-right' : 'text-left'}`}>{t("text_unboxing_videos")}</h3>
      </Card.Header>
      <Card.Body>
        <div className="d-flex flex-column video-light-gallery-item">
          {videos && videos.length > 0 ? (
            videos.map((video) => (
              <div className="mb-2" style={{display: "flex", flexDirection: "row", alignItems:"center"}} key={video.videoId}>
                <LightGallery
                  speed={500}
                  dynamic={true}
                  plugins={[lgThumbnail, lgZoom]}
                  galleryId={video.videoId}
                > 
                  <a href={video.link} target="_blank" rel="noopener noreferrer">
                    <img
                      src={video.thumbnail}
                      alt="video-thumbnail"
                      className="video-thumbnail"
                      width={120}
                      height={90}
                    />
                  </a>
                </LightGallery>
                <div className={`video-detail-container ${isRtl ? 'text-right me-2' : 'text-left'}`}>
                  <span className="video-title">{video.title}</span>
                  <span className="text-muted" style={{ fontSize: 12 }}>
                    Likes {video.likes ? video.likes.toLocaleString() : 0}
                  </span>
                </div>
              </div>
            ))
          ) : (
            Array.from({ length: placeholderCount }).map((_, index) => (
              <Placeholder animation="glow" key={index} className="mb-2">
                <div className="d-flex">
                  <Placeholder
                    className="video-thumbnail"
                    style={{ width: 120, height: 90 }}
                  />
                  <div style={{ width: "100%" }}>
                    <Placeholder
                      style={{
                        width: "90%",
                        marginLeft: 5,
                        marginTop: 15,
                        height: 20,
                      }}
                    >
                      &nbsp;
                    </Placeholder>
                    <Placeholder
                      style={{
                        width: "70%",
                        marginLeft: 5,
                        marginTop: 15,
                        height: 20,
                      }}
                    >
                      &nbsp;
                    </Placeholder>
                  </div>
                </div>
              </Placeholder>
            ))
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  videos: state.home.selectedItem.unboxingVideos,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Unboxingvideos);