import { Alert, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../helpers/api";
import { useEffect, useState } from "react";
import { handleImageError, removeHtmlTags, renderPrice } from "../../helpers/common";
import Trending from "./trending";
import Homepageslider from "./homepageslider";
import Upcomming from "./upcomming";
import { useTranslation } from "react-i18next";
import axios from "axios";
import HomeShimarLayout from "../../shimar/home";

const Home = ({
  initialState,
  initHomePage,
  isPageLoaded,
  setIsPageLoaded,
  isHomeCall,
  setIsHomeCall,
  setAlertMessage,
  initSelectedItem
}) => {
  const { t, i18n } = useTranslation();
  const wishlist = localStorage.getItem("wishlist");
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState({});
  const isRtl = i18n.language === 'ur' || i18n.language === 'ar';
  const navigate = useNavigate();

  const getPageData = () => {
    axios.get(`${BASE_URL}/api/guest_home_page`, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        if (res.status === 200) {
          setPageData(res.data);
        }
      })
      .catch(err => {
        console.log("Error in getPageData()", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getPageData();
  }, []);

  const addToWishlist = async (item) => {
    let wishlist = localStorage.getItem("wishlist");

    if (!wishlist) {
      let wishlistArray = [item];
      localStorage.setItem("wishlist", JSON.stringify(wishlistArray));
      setAlertMessage({
        show: true,
        type: "success",
        heading: "Alert",
        body: "Item added successfully into wishlist",
        timeout: 5000
      });
    } else {
      let parsedWishlist = JSON.parse(wishlist);
      const isInWishlist = parsedWishlist.some((wishlistItem) => wishlistItem.id === item.id);

      if (!isInWishlist) {
        parsedWishlist.push(item);
        localStorage.setItem("wishlist", JSON.stringify(parsedWishlist));
        setAlertMessage({
          show: true,
          type: "success",
          heading: "Alert",
          body: "Item added successfully into wishlist",
          timeout: 5000
        });
      } else {
        const updatedWishlist = parsedWishlist.filter(wishlistItem => wishlistItem.id !== item.id);
        localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));

        setAlertMessage({
          show: true,
          type: "error",
          heading: "Alert",
          body: "Item removed from the wishlist.",
          timeout: 5000
        });
      }
    }
  }

  const compareItem = (event, item) => {
    event.preventDefault();
    event.stopPropagation();

    initSelectedItem(item);
    navigate(`/compare/${item.id}`);
  }

  return (
    loading ? <HomeShimarLayout />
      :
      <>
        <Homepageslider data={pageData?.slider} />
        <Trending data={pageData?.trending} />
        <Container className={`mt-3 mb-3 ${isRtl ? 'direction-rtl' : 'direction-left'}`}>
          <div className="row" style={{ gap: 15 }}>
            <h3 className={`latest-heading mb-0 ${isRtl ? 'text-right' : 'text-left'}`}>{t("txt_latest")}</h3>
            {pageData?.latest?.length > 0 ?
              pageData.latest.map((item, key) => (
                <Link
                  to={`/product/detail/${item.id}/${item.name.replace(/\s+/g, '-').replace(/'/g, '')}`}
                  className="col d-flex flex-column align-items-center justify-content-center p-2 mobile-card"
                  style={{
                    background: "white",
                    borderRadius: 0,
                    textDecoration: "none",
                    color: "black",
                  }}
                  key={key}
                >
                  <img
                    className="img-mobile lazyloaded"
                    src={`${BASE_URL}/${item.thumbnail}`}
                    onError={handleImageError}
                    alt="Mobile"
                  />
                  <p className="truncate mt-2 mb-0 text-center">
                    {removeHtmlTags(atob(item.description))}
                  </p>
                  <h4 className="mb-0 text-center">{item.name}</h4>
                  {renderPrice(item, initialState.selected_currency, true)}
                  <div className="overlay" style={{ zIndex: 1 }}>
                    {/* {item.available_stock !== null && <Button variant="outline-primary" onClick={(e) => handleAddToCartClick(e, item)} style={{zIndex: 1000}}><i className="mdi mdi-cart text-white fa-2x me-2"></i></Button> } */}
                    <Link to={"#"} onClick={() => addToWishlist(item)}><i className={`mdi mdi-heart fa-2x me-2 ${wishlist && wishlist.length > 0 && wishlist.includes(item.id) ? 'text-danger' : 'text-white'}`}></i></Link>
                    <Link to={"#"} onClick={(event) => compareItem(event, item)}><i className="mdi mdi-compare text-white fa-2x"></i></Link>
                  </div>
                </Link>
              )) : <Alert variant="danger">
                <strong style={{ fontWeight: 600, lineHeight: '28px', fontSize: 20 }}>{t("txt_alert")}</strong>
                <p className="mb-0" style={{ fontWeight: 400, lineHeight: '28px', fontSize: 18 }}>{t("txt_not_found_any_item")}</p>
              </Alert>}
          </div>
        </Container>
        <Upcomming data={pageData?.up_coming} />
      </>
  )
};
const mapStateToProps = (state) => ({
  initialState: state.home,
  isPageLoaded: state.home.isPageLoaded,
  isHomeCall: state.home.isHomeCall,
  mobileScreenView: state.home.mobileScreenView
});

const dispatchStateToProps = (dispatch) => ({
  initHomePage: (data) => {
    dispatch({
      type: "INIT_GUEST_HOME_PAGE",
      payload: data,
    });
  },
  updateTotalCount: (totalCount) => {
    dispatch({
      type: "UPDATE_TOTAL_COUNT",
      payload: totalCount,
    });
  },
  setIsPageLoaded: (value) => {
    dispatch({
      type: "UPDATE_IS_PAGE_LOADED",
      payload: value,
    });
  },
  setIsHomeCall: (value) => {
    dispatch({
      type: "UPDATE_IS_HOME_CALL",
      payload: value,
    });
  },
  initSelectedItem: (data) => {
    dispatch({
      type: "INIT_SELECTED_ITEM",
      payload: data,
    });
  },
  setAlertMessage: (obj) => {
    dispatch({
      type: "UPDATE_ALERT_MESSAGE",
      payload: obj
    });
  }
});
export default connect(mapStateToProps, dispatchStateToProps)(Home);
