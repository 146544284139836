import LightGallery from "lightgallery/react";
// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

import { Carousel } from "react-bootstrap";
import { connect } from "react-redux";
import { BASE_URL } from "../helpers/api";
import ProductSliderShimarLayout from "../shimar/ProductSliderShimarLayout";
import { handleImageError } from "../helpers/common";

const Productslider = ({ attachments }) => {
  if (attachments && attachments.length > 0) {
    return (
      <>
        <div
          style={{
            borderTop: "3px solid #e5e5e5",
            borderLeft: "3px solid #e5e5e5",
            borderRight: "3px solid #e5e5e5",
            maxWidth: "100%",
            padding: 15,
            borderTopRightRadius: 15,
            borderTopLeftRadius: 15,
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignItems:"center"
          }}
        >
          <Carousel
            indicators={false}
            autoPlay
            controls={attachments.length === 1 ? false : true}
          >
            {attachments.map((thumb, key) => (
              <Carousel.Item key={key}>
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                    }}
                    height={400}
                    src={`${BASE_URL}/${thumb.url}`}
                    alt="First slide"
                    onError={handleImageError}
                  />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        <div style={{ 
          width: "100%", 
          padding: "15px", 
          background: "white",
          borderLeft: "3px solid #e5e5e5", 
          borderRight: "3px solid #e5e5e5", 
          borderBottom: "3px solid #e5e5e5",
          borderBottomLeftRadius: 15,
          borderBottomRightRadius: 15,
        }} className="d-flex">
          <div
            className="d-flex flex-row justify-content-center align-items-center mt-0 bg-white"
            style={{ paddingBottom: 15, overflowX: "auto" }}
          >
            <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]}>
              {attachments.map((thumb, key) => (
                <a href={`${BASE_URL}/${thumb.url}`} key={key}>
                  <img
                    src={`${BASE_URL}/${thumb.url}`}
                    alt="Thumbnail"
                    height={50}
                    width={50}
                    style={{
                      border: "1px solid #e5e5e5",
                      padding: 5,
                      marginRight: 2,
                      objectFit: "contain",
                    }}
                    onError={handleImageError}
                  />
                </a>
              ))}
            </LightGallery>
          </div>
        </div>
      </>
    );
  } else {
    return <ProductSliderShimarLayout />;
  }
};

const mapStateToProps = (state) => ({
  attachments: state.home.selectedItem.attachments,
});

const dispatchStateToProps = (dispatch) => ({});
export default connect(mapStateToProps, dispatchStateToProps)(Productslider);
