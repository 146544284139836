import { Carousel, Container, Row } from "react-bootstrap";
import { handleImageError, removeHtmlTags, renderPrice } from "../../helpers/common";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../helpers/api";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const Upcomming = ({ data, selectedCurrency }) => {
    const { t, i18n } = useTranslation();
    const isRtl = i18n.language === 'ur' || i18n.language === 'ar';
    const [itemsPerSlide, setItemsPerSlide] = useState(5);
    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 992) {
                setItemsPerSlide(5);
            } else {
                setItemsPerSlide(2);
            }
        }

        window.addEventListener('resize', handleResize);

        // Initial resize check
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [itemsPerSlide]);

    let numSlides = Math.ceil(data && data.length / itemsPerSlide);
    return (
        <Container style={{ paddingLeft: 0, paddingRight: 0, marginBottom: 15, direction: isRtl ? 'rtl' : 'ltr' }}>
            <h3 className={`upcoming-heading ${isRtl ? 'text-right': 'text-left'}`}>{t("txt_up_comming")}</h3>
            <Carousel indicators={false}>
                {Array.from({ length: numSlides }).map((_, slideIndex) => (
                    <Carousel.Item key={slideIndex}>
                        {/* <Container style={{ paddingRight: 0, paddingLeft: 0 }}> */}
                        <Row style={{ gap: 15 }}>
                            {data.slice(
                                slideIndex * itemsPerSlide,
                                (slideIndex + 1) * itemsPerSlide
                            ).map((item, itemIndex) => (
                                <Link
                                    to={`/product/detail/${item.id}/${item.name.replace(/\s+/g, '-').replace(/'/g, '')}`}
                                    className="col d-flex flex-column align-items-center justify-content-center p-2 mobile-card"
                                    style={{
                                        background: "white",
                                        borderRadius: 0,
                                        textDecoration: "none",
                                        color: "black",
                                    }}
                                    key={itemIndex}
                                >
                                    <img
                                        className="img-mobile lazyloaded"
                                        src={`${BASE_URL}/${item.thumbnail}`}
                                        onError={handleImageError}
                                        alt="Mobile"
                                    />
                                    <p className="truncate mt-2 mb-0 text-center">
                                        {removeHtmlTags(atob(item.description))}
                                    </p>
                                    <h4 className="mb-0 text-center">{item.name}</h4>
                                    {renderPrice(item, selectedCurrency, true)}
                                </Link>
                            ))}
                        </Row>
                        {/* </Container> */}
                    </Carousel.Item>
                ))}
            </Carousel>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    selectedCurrency: state.home.selected_currency
});

export default connect(mapStateToProps, {})(Upcomming);