import { Link, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../helpers/api";
import Filters from "../includes/filters";
import axios from "axios";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { handleImageError, removeHtmlTags, renderPrice } from "../../helpers/common";
import ProductsByBrandShimarLayout from "../../shimar/product.by.brand";
import { PaginationControl } from "react-bootstrap-pagination-control";

const ProductsByBrand = ({ selectedCurrency, setAlertMessage, filterModal, setSelectedItemForCompare }) => {
  const navigate = useNavigate();
  const [displayView, setDisplayView] = useState('gird');
  const wishlist = localStorage.getItem("wishlist");
  const { catId, slug, brandId, brandName } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedPageNumber, setSelectedPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(32);
  const [productList, setProductList] = useState({});

  const getProducts = (brand_id, page, category_id) => {
    setLoading(true);
    const requestBody = {
      brand_id: brand_id,
      page: page,
      category_id: category_id,
      limit: pageLimit,
      filters: filterModal
    }
    axios.post(`${BASE_URL}/api/guest_home_page`, requestBody, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => {
      console.log(res);
      if (res.status === 200) {
        setLoading(false);
        setProductList(res?.data);
      }
    }).catch(err => {
      console.log(err);
    })
  }

  useEffect(() => {
    getProducts(brandId, selectedPageNumber, catId);
  }, [brandId, selectedPageNumber, filterModal]);

  const handlePageChange = (pageNumber) => {
    console.log(pageNumber);
    setSelectedPageNumber(pageNumber);
  };

  const addToWishlist = async (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    let wishlist = localStorage.getItem("wishlist");

    if (!wishlist) {
      let wishlistArray = [item];
      localStorage.setItem("wishlist", JSON.stringify(wishlistArray));
      setAlertMessage({
        show: true,
        type: "success",
        heading: "Alert",
        body: "Item added successfully into wishlist",
        timeout: 5000
      });
    } else {
      let parsedWishlist = JSON.parse(wishlist);
      const isInWishlist = parsedWishlist.some((wishlistItem) => wishlistItem.id === item.id);

      if (!isInWishlist) {
        parsedWishlist.push(item);
        localStorage.setItem("wishlist", JSON.stringify(parsedWishlist));
        setAlertMessage({
          show: true,
          type: "success",
          heading: "Alert",
          body: "Item added successfully into wishlist",
          timeout: 5000
        });
      } else {
        const updatedWishlist = parsedWishlist.filter(wishlistItem => wishlistItem.id !== item.id);
        localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));

        setAlertMessage({
          show: true,
          type: "error",
          heading: "Alert",
          body: "Item removed from the wishlist.",
          timeout: 5000
        });
      }
    }
  }

  useEffect(() => {
    console.log(displayView);
  }, [displayView]);

  useEffect(() => {
    console.log("filterModal", filterModal);
  }, [filterModal]);

  const compareItem = (event, item) => {
    event.preventDefault();
    event.stopPropagation();

    setSelectedItemForCompare(item);
    navigate(`/compare/${item.id}`);
  }

  return (
    loading ? <ProductsByBrandShimarLayout /> :
      <div className="container">
        <div className="row mt-3 mb-3">

          <div className="col-sm-12 col-md-3">
            <Filters filters={productList && Object.keys(productList).length > 0 && productList.filters && productList.filters.length > 0 && productList.filters} setSelectedPageNumber={setSelectedPageNumber}/>
          </div>

          <div className="col-sm-12 col-md-9">
            <div className="row mb-3">
              <div className="col-md-6">
                <div>
                  <h2 className="mb-0">Search Results</h2>
                  <span className="badge badge-info rounded-pill me-2">{brandName}</span>
                  {filterModal && filterModal.length > 0 && filterModal.map((item) => (
                    <span className="badge badge-info rounded-pill me-2">{item.title}</span>
                  ))}
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-end">
                <Link to={"#"} onClick={() => setDisplayView('list')}>
                  <i className={`fa fa-th-list ${displayView === 'list' ? 'text-info' : 'text-muted'}`}></i>
                </Link>
                &nbsp;&nbsp;
                <Link to={"#"} onClick={() => setDisplayView('gird')}>
                  <i className={`fa fa-th ${displayView === 'gird' ? 'text-info' : 'text-muted'}`}></i>
                </Link>
              </div>
            </div>

            {productList.results && productList.results.length > 0 ?
              <>
                {displayView === 'gird' ? (
                  <div className="row mb-3" style={{ gap: 15 }}>
                    {productList.results && productList.results.length > 0 && productList.results.map((item, key) => (
                      <Link
                        to={`/product/detail/${item.id}/${item.name.replace(/\s+/g, '-').replace(/'/g, '')}`}
                        className="col d-flex flex-column align-items-center justify-content-center p-2 mobile-card"
                        style={{
                          background: "white",
                          borderRadius: 0,
                          textDecoration: "none",
                          color: "black",
                        }}
                        key={key}
                      >
                        <img
                          className="img-mobile lazyloaded"
                          src={`${BASE_URL}/${item.thumbnail}`}
                          onError={handleImageError}
                          alt="Mobile"
                        />
                        <p className="truncate mt-2 mb-0 text-center">
                          {removeHtmlTags(atob(item.description))}
                        </p>
                        <h4 className="mb-0 text-center">{item.name}</h4>
                        {renderPrice(item, selectedCurrency, true)}
                        <div className="overlay" style={{ zIndex: 1 }}>
                          <Link to={"#"} onClick={(e) => addToWishlist(e, item)}>
                            <i className={`mdi mdi-heart fa-2x me-2 ${wishlist && wishlist.length > 0 && wishlist.includes(item.id) ? 'text-danger' : 'text-white'}`}></i>
                          </Link>
                          <Link to={"#"} onClick={(event) => compareItem(event, item)}><i className="mdi mdi-compare text-white fa-2x"></i></Link>
                        </div>
                      </Link>
                    ))}
                  </div>
                ) : (
                  <div>
                    {productList && Object.keys(productList).length > 0 ? (
                      productList.results && productList.results.length > 0 && productList.results.map((item, key) => (
                        <a key={key} href={`/product/detail/${item.id}/${item.name.replace(/\s+/g, '-').replace(/'/g, '')}`}>
                          <Card>
                            <Card.Body>
                              <Row>
                                <Col xs={4} md={3} className="text-center">
                                  <img
                                    className="img-mobile lazyloaded maxWidth-100"
                                    src={`${BASE_URL}/${item.thumbnail}`}
                                    onError={handleImageError}
                                    alt="Mobile"
                                  />
                                </Col>
                                <Col xs={9} md={9}>
                                  <h4 className="mb-0">{item.name}</h4>
                                  {renderPrice(item, selectedCurrency, true)}
                                  <p className="truncate-description mt-2">
                                    {removeHtmlTags(atob(item.description))}
                                  </p>
                                  <div className="d-flex">
                                    <Button variant={wishlist && wishlist.length > 0 && wishlist.includes(item.id) ? 'danger' : 'outline-danger'} onClick={(e) => addToWishlist(e, item)} className="me-2">
                                      <i className={`mdi mdi-heart fa-1x`}></i>
                                      Add To Wishlist
                                    </Button>
                                    <Button variant="outline-info" onClick={(event) => compareItem(event, item)}>
                                      <i className="mdi mdi-compare text-primary fa-1x"></i> Compare
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </a>
                      ))
                    ) : null}
                  </div>
                )}

                < div className="row">
                  <div className="col-sm-12 col-md-4 d-flex align-items-center bold">
                    <p>Showing {productList?.totalResults < pageLimit ? productList?.totalResults : pageLimit} from {productList?.totalResults} products</p>
                  </div>
                  <div className="col-sm-12 col-md-8 d-flex justify-content-end">
                    <PaginationControl
                      page={selectedPageNumber}
                      between={4}
                      total={productList?.totalResults}
                      limit={pageLimit}
                      changePage={(_page) => {
                        handlePageChange(_page)
                      }}
                      ellipsis={1}
                    />
                  </div>
                </div>
              </>
              :
              <Alert variant="warning">
                <strong style={{ fontWeight: 600, lineHeight: '28px', fontSize: 20 }}>Alert!</strong>
                <p className="mb-0" style={{ fontWeight: 400, lineHeight: '28px', fontSize: 18 }}>Not found any product.</p>
              </Alert>
            }
          </div>
        </div>
      </div >

  );
};
const mapStateToProps = (state) => ({
  selectedCurrency: state.home.selected_currency,
  alertMessage: state.home.alertMessage,
  filterModal: state.home.filterModal
});
const dispatchStateToProps = (dispatch) => ({
  setAlertMessage: (obj) => {
    dispatch({
      type: "UPDATE_ALERT_MESSAGE",
      payload: obj
    });
  },
  setSelectedItemForCompare: (data) => {
    dispatch({
      type: "UPDATE_SELECTED_ITEM_FOR_COMPARE",
      payload: data,
    });
  }
});
export default connect(mapStateToProps, dispatchStateToProps)(ProductsByBrand);
