import { Card, Carousel, Container, Placeholder, Row } from "react-bootstrap";
import MobileCard from "./MobileCard";
import "holderjs";

const HomeShimarLayout = () => {
    const itemsPerSlide = 5;
    let numSlides = Math.ceil(10 / itemsPerSlide);
    const isMobile = window.innerWidth <= 576;
    return (
        <>
            <Carousel data-bs-theme="dark" indicators={false}>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        data-src={!isMobile ? "holder.js/1024x600?text=&bg=f5f5f5" : "holder.js/1024x300?text=&bg=f5f5f5"}
                        alt="slider-image-1"
                    />
                    <Carousel.Caption>
                        <Placeholder xs={12} animation="glow" />
                        <Placeholder xs={12} animation="glow" />
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        data-src={!isMobile ? "holder.js/1024x600?text=&bg=f5f5f5" : "holder.js/1024x300?text=&bg=f5f5f5"}
                        alt="slider-image-2"
                    />
                    <Carousel.Caption>
                        <Placeholder xs={12} animation="glow" />
                        <Placeholder xs={12} animation="glow" />
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        data-src={!isMobile ? "holder.js/1024x600?text=&bg=f5f5f5" : "holder.js/1024x300?text=&bg=f5f5f5"}
                        alt="slider-image-3"
                    />
                    <Carousel.Caption>
                        <Placeholder xs={12} animation="glow" />
                        <Placeholder xs={12} animation="glow" />
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>

            <Container className={`mt-3 mb-3`}>
                <Placeholder animation="glow">
                    <Placeholder xs={2} style={{ height: 24, borderRadius: 10, marginBottom: "0.5rem" }} />
                </Placeholder>
                <Carousel indicators={false}>
                    {Array.from({ length: numSlides }).map((_, slideIndex) => (
                        <Carousel.Item key={slideIndex}>
                            <Container style={{ paddingRight: 0, paddingLeft: 0 }}>
                                <MobileCard count={2} />
                            </Container>
                        </Carousel.Item>
                    ))}
                </Carousel>

                <Placeholder animation="glow">
                    <Placeholder xs={2} style={{ height: 24, borderRadius: 10, marginBottom: "0.5rem", marginTop: "0.5rem" }} />
                </Placeholder>
                <MobileCard count={55} />

                <Placeholder animation="glow">
                    <Placeholder xs={2} style={{ height: 24, borderRadius: 10, marginBottom: "0.5rem", marginTop: "0.5rem" }} />
                </Placeholder>
                <Carousel indicators={false}>
                    {Array.from({ length: numSlides }).map((_, slideIndex) => (
                        <Carousel.Item key={slideIndex}>
                            <Container style={{ paddingRight: 0, paddingLeft: 0 }}>
                                <MobileCard count={2} />
                            </Container>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Container>

        </>
    );
}

export default HomeShimarLayout;
