import { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import { connect } from "react-redux";
import Rating from "../rating";
import { postData } from "../../helpers/api";
import { formatDate, generateRandomColor } from "../../helpers/common";
import { useTranslation } from "react-i18next";

const Reviews = ({ reviews, item_id, updateReviews, updateAlertMessage }) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ur' || i18n.language === 'ar';
  const [validated, setValidated] = useState(false);
  const [userRatingCount, setUserRatingCount] = useState(0);
  const [isRequired, setIsRequired] = useState(false);
  const [formData, setFormData] = useState({
    thoughts: "",
    name: "",
    email: "",
    saveInfo: false,
  });
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      if (userRatingCount === 0) {
        setIsRequired(true);
      } else {
        setIsRequired(false);
      }
      return;
    }

    let newFormData = formData;
    newFormData["rating"] = userRatingCount;
    newFormData["item_id"] = item_id;

    postData("/api/save_review", newFormData, { headers: { "Content-Type": 'application/json' } })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          updateAlertMessage({ message: response.message, type: "success" });
          updateReviews(response.data);
          setValidated(false);
          setFormData({
            thoughts: "",
            name: "",
            email: "",
            saveInfo: false,
          });
          setUserRatingCount(0);
        } else {
          updateAlertMessage({ message: response.message, type: "error" });
          console.log(response.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === "checkbox" ? checked : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: fieldValue,
    }));
  };

  const handleStarCount = (count) => {
    setUserRatingCount(count);
    setIsRequired(false);
  };

  return (
    <div className={`mt-3`}>
      <Card className="mb-3 reviews-card">
        <Card.Header>
          <h2 className={`mb-0 ${isRtl ? 'text-right' : 'text-left'}`}>{t("txt_add_a_review")}</h2>
        </Card.Header>
        <Card.Body className={`${isRtl ? 'direction-rtl' : 'direction-ltr'}`}>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            className="p-15"
          >
            <Form.Group className={`${isRtl ? 'text-right' : 'text-left'}`}>
              <Rating
                count={userRatingCount}
                editing={true}
                setUserRatingCount={handleStarCount}
                isRequired={isRequired}
                isTextShow={true}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                as="textarea"
                rows={3}
                placeholder={t("placeholder_your_toughts")}
                name="thoughts"
                onChange={handleChange}
                value={formData.thoughts}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="validationName">
              <Form.Control
                required
                type="text"
                placeholder={t("placeholder_name")}
                name="name"
                onChange={handleChange}
                value={formData.name}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="valitaionEmail">
              <Form.Control
                required
                type="email"
                placeholder={t("placeholder_email")}
                name="email"
                onChange={handleChange}
                value={formData.email}
              />
            </Form.Group>
            <Form.Group className={`${isRtl ? 'text-right' : 'text-left'}`}>
              <Form.Check
                type="checkbox"
                id="custom-switch"
                name="saveInfo"
                onChange={handleChange}
                value={formData.saveInfo}
                label={t("txt_save_user_information_permission")}
              />
            </Form.Group>
            <div className={isRtl ? 'd-flex direction-rtl' : 'direction-ltr'}>
              <Button variant="primary" type="submit" className="mb-0 mt-2">
                {t("button_txt_post_review")}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      {reviews && reviews.length > 0 ? (
        <Card className="reviews-card mb-3">
          <Card.Header>
            <h1 className="mb-0">{t("txt_reviews")}</h1>
          </Card.Header>
          <Card.Body>
            <ListGroup>
              {reviews &&
                reviews.length > 0 &&
                reviews.map((review, key) => (
                  <ListGroupItem key={key}>
                    <Row>
                      <Col xs={12} md={1}>
                        <div
                          className="user-image-with-first-latter"
                          style={{ background: generateRandomColor() }}
                        >
                          {review.name.charAt(0).toUpperCase()}
                        </div>
                      </Col>
                      <Col xs={12} md={9}>
                        <p className="mb-0">{review.review}</p>
                        <small
                          className="text-muted"
                          style={{ fontWeight: 300 }}
                        >
                          {formatDate(review.created_at)}
                        </small>
                      </Col>
                      <Col xs={12} md={2} className="text-right mt-2 mt-md-0">
                        <Rating
                          editing={false}
                          count={review.rating}
                          isTextShow={false}
                        />
                      </Col>
                    </Row>
                  </ListGroupItem>
                ))}
            </ListGroup>
          </Card.Body>
        </Card>
      ) : null}

    </div>
  );
};

const mapStateToProps = (state) => ({
  reviews: state.home.selectedItem.reviews,
  item_id: state.home.selectedItem.item_id,
});
const dispatchStateToProps = (dispatch) => ({
  updateReviews: (review) => {
    dispatch({
      type: "UPDATE_REVIEWS",
      payload: review,
    });
  },
  updateAlertMessage: (msg) => {
    dispatch({
      type: "UPDATE_ALERT_MESSAGE",
      payload: msg,
    });
  },
});
export default connect(mapStateToProps, dispatchStateToProps)(Reviews);
