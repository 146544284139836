import axios from "axios";
import { useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../helpers/api";
import { useTranslation } from "react-i18next";

const Footer = ({ initialState, setAlertMessage }) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ur' || i18n.language === 'ar';
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    email: ''
  });

  const onSubmitNewSubscriber = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    axios.post(`${BASE_URL}/api/addSubsriber`, formData, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => {
      if (res.status === 200) {
        setAlertMessage({
          show: true,
          type: res?.data?.status,
          heading: "Alert",
          body: res?.data?.message,
          timeout: 5000
        });
        setFormData({
          email: ''
        });
        setValidated(false);
      }
    }).catch(err => {
      console.log(err);
    });
  }

  return (
    <Container fluid style={{ minHeight: "300px", background: "#f8f8f8", direction: isRtl ? 'rtl' : 'ltr' }}>
      <Container className="p-2">
        <Row>
          <Col>
            <h1 className={`logo-header ${isRtl ? 'text-right' : 'text-left'}`}>{t("txt_pakmallz")}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <p style={{ lineHeight: "1.5em", letterSpacing: 0, textAlign: isRtl ? 'right' : 'left' }}>{t("txt_home_page_footer_description")}</p>
          </Col>
          <Col xs={6} md={2}>
            <ul className={`customList ${window.innerWidth <= 576 ? "pl-0" : ""}`}>
              <li className={`${isRtl ? 'text-right' : 'text-left'}`}>
                <Link to={"/"}>{t("txt_home")}</Link>
              </li>
              <li className={`${isRtl ? 'text-right' : 'text-left'}`}>
                <Link to={"/compare/null"}>{t("txt_compare")}</Link>
              </li>
              <li className={`${isRtl ? 'text-right' : 'text-left'}`}>
                <Link to={"/wishlist"}>{t("txt_wishlist")}</Link>
              </li>
              <li className={`${isRtl ? 'text-right' : 'text-left'}`}>
                <Link to={"/faq"}>{t("txt_faq")}</Link>
              </li>
              <li className={`${isRtl ? 'text-right' : 'text-left'}`}>
                <Link to={"/contact-us"}>{t("txt_contact_us")}</Link>
              </li>
            </ul>
          </Col>
          <Col xs={6} md={2}>
            <ul className={`customList ${window.innerWidth <= 576 ? "pl-0" : ""}`}>
              <li className={`${isRtl ? 'text-right' : 'text-left'}`}>
                <Link to={"/privacy-policy"}>{t("txt_privacy_policy")}</Link>
              </li>
              <li className={`${isRtl ? 'text-right' : 'text-left'}`}>
                <Link to={"/installment-plans"}>{t("txt_installment_plan")}</Link>
              </li>
              <li className={`${isRtl ? 'text-right' : 'text-left'}`}>
                <Link to={"/sitemap"}>{t("txt_sitemap")}</Link>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={4}>
            <h3 className="get-notification-text">{t("txt_footer_subscribor_title")}</h3>
            <Form
              noValidate
              validated={validated}
              onSubmit={(event) => onSubmitNewSubscriber(event)}
            >
              <InputGroup size="lg">
                <Form.Control
                  aria-label="Large"
                  aria-describedby="inputGroup-sizing-sm"
                  placeholder={t("placeholder_enter_email_address_here")}
                  type="email"
                  required
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  style={isRtl ? {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 5, borderBottomRightRadius: 5} : {}}
                />
                <InputGroup.Text id="inputGroup-sizing-lg" style={isRtl ? {borderTopRightRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, background: 'white', borderRight: 0} : { background: "white" }}>
                  <Button type="submit" variant="link" style={{ padding: 0 }}>
                    <i className="fa fa-telegram" aria-hidden="true"></i>
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            </Form>
            <div className={`mt-3 ${isRtl && 'd-flex direction-rtl'}`}>
              <Button variant={`primary ${isRtl ? 'ml-2' : 'mr-2'}`} className="rounded-circle" as={Link} to={"https://www.twitter.com/pakmallz/"} target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></Button>
              <Button variant={`primary ${isRtl ? 'ml-2' : 'mr-2'}`} className="rounded-circle" as={Link} to={"https://www.facebook.com/pakmallz/"} target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></Button>
              <Button variant={`primary ${isRtl ? 'ml-2' : 'mr-2'}`} className="rounded-circle" as={Link} to={"https://www.instagram.com/pakmallz876/"} target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></Button>
              <Button variant="primary" className="rounded-circle" as={Link} to={"https://www.youtube.com/channel/UCadGGSUaXThMNLTRExDyjQA"} target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube"></i></Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  initialState: state.home,
});

const dispatchStateToProps = (dispatch) => ({
  setAlertMessage: (obj) => {
    dispatch({
      type: "UPDATE_ALERT_MESSAGE",
      payload: obj
    });
  }
});
export default connect(mapStateToProps, dispatchStateToProps)(Footer);