import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Specifications = ({ generalSpecs, mobileId }) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ur' || i18n.language === 'ar';

  if (generalSpecs && generalSpecs.length > 0) {
    return (
      <Card className="specification-card mb-3">
        <Card.Header>
          <h2 className={`mb-0 ${isRtl ? 'text-right' : 'text-left'}`}>{t("txt_specifications")}</h2>
        </Card.Header>
        <Card.Body className={isRtl ? 'text-right' : 'text-left'}>
          {generalSpecs &&
            generalSpecs.length > 0 &&
            generalSpecs.map((gen) => {
              if (gen.details.length > 0) {
                return (
                  <div key={gen.id}>
                    <h6 className="my-card-title">
                      <div className="icon">
                        <img src={"/" + gen.icon} alt="icon" />
                      </div>
                      {gen.name}
                    </h6>
                    <div className="table-responsive">
                      <table className="table table-default specification-table">
                        <tbody>
                          {gen.details.map((d) => {
                            if (d.is_prominent === "0") {
                              return (
                                <tr key={d.id}>
                                  <th
                                    style={
                                      !/Mobi/i.test(navigator.userAgent)
                                        ? {
                                          width: "130px",
                                          color: "gray",
                                          fontSize: 15,
                                        }
                                        : { color: "gray" }
                                    }
                                  >
                                    {d.name}
                                  </th>
                                  <td style={{ fontSize: 13 }}>{d.value}</td>
                                </tr>
                              );
                            }
                            return null;
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          <div className="d-grid gap-2">
            <Button
              variant="primary"
              size="lg"
              as={Link}
              to={`/compare/${mobileId}`}
            >
              {t("txt_compare")}
            </Button>
          </div>
        </Card.Body>
      </Card>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  generalSpecs: state.home.selectedItem.generals,
  mobileId: state.home.selectedItem.item_id,
});
const dispatchStateToProps = (dispatch) => ({});
export default connect(mapStateToProps, dispatchStateToProps)(Specifications);
