import React from "react";
import StarRatingComponent from "react-star-rating-component";

const Rating = ({ count, editing, setUserRatingCount, isRequired, isTextShow }) => {
  const handleStartClick = (nextValue, prevValue, name) => {
    if(editing){
        setUserRatingCount(nextValue);
    }
  };
  return (
    <>
      <StarRatingComponent
        name="rate1"
        starCount={5}
        value={parseInt(count)}
        emptyStarColor="#e5e5e5"
        editing={editing}
        onStarClick={handleStartClick}
      />
      {isTextShow ? <br /> : null }
      <div className="d-flex flex-column">
      {isTextShow ? <span style={{ fontSize: 11, marginTop: 5, fontWeight: 300 }}> {count} out of 5.0</span> : null }
      {isRequired ? <small className="text-danger" style={{ fontSize: 11, fontWeight: 300 }}>This field is required</small> : null }
      </div>
    </>
  );
};

export default Rating;
