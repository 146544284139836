import { useEffect, useState } from "react";
import { Card, Placeholder } from "react-bootstrap";
import { connect } from "react-redux";

const Prominentspecs = ({ prominents }) => {
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    setInitialLoad(false);
  }, []);

  if (initialLoad && prominents.length === 0) {
    return (
      <Card className="mr-2 price-card">
        <Card.Body>
          <Placeholder animation="glow">
            <div
              className="d-flex flex-row"
              style={{ justifyContent: "space-between" }}
            >
              <Placeholder style={{ width: "100%" }} />
            </div>
          </Placeholder>
        </Card.Body>
      </Card>
    );
  }

  if (prominents && prominents.length > 0) {
    return (
      <Card>
        <Card.Header>
        <h3 class="mb-0 text-left">Prominent Specifications</h3>
        </Card.Header>
        <Card.Body>
          <div className="row">
            {prominents.map((val, key) => (
              <div
                className={`col-md-6 ${
                  window.innerWidth <= 576 ? "mb-1" : "mb-2"
                }`}
                style={(key%2===0 && window.innerWidth >= 576 )? {paddingRight: 0} : {}}
                key={key}
              >
                <Card className="prominent-card bg-light" key={val.name}>
                  <Card.Body>
                    <img src={val.icon} width={30} height={30} alt="icon" />{" "}
                    &nbsp;&nbsp;
                    <span style={{ fontSize: 12, fontWeight: 300 }}>
                      {val.name}
                    </span>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </Card.Body>
      </Card>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    prominents: state.home.selectedItem.prominents,
  };
};

export default connect(mapStateToProps)(Prominentspecs);
