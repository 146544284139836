import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const ThankYouPage = () => {
  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col md={12} xs={12}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Thank You!</h2>
              <p>Your order has been placed successfully. You will receive a confirmation email shortly.</p>
              <p>If you have any questions, please contact our customer support.</p>
              <div className="text-center mt-4">
                <a href="/" className="btn btn-primary">Go to Home</a>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ThankYouPage;
