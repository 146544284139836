import Carousel from 'react-bootstrap/Carousel';
import "holderjs";
import { BASE_URL } from '../../helpers/api';

const Homepageslider = ({ data }) => {
  const isMobile = window.innerWidth <= 576;
  return (
    <Carousel className='mb-3'>
      {data && data.length > 0 && data.map((slide, key) => (
        <Carousel.Item key={key}>
          <img
            className="d-block w-100"
            src={BASE_URL + "/uploads/" + slide.image}
            alt={slide.name}
            height={isMobile ? 300 : 600}
          />
          <Carousel.Caption>
            <h3>{slide.name}</h3>
            <p>{slide.description}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default Homepageslider;