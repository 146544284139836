import { Placeholder, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { renderPrice } from "../helpers/common";
import { useTranslation } from "react-i18next";

const Prices = ({ prices, selectedCurrency }) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ur' || i18n.language === 'ar';
  if (prices && prices.length > 0) {
    return (
      <div className={`d-flex mt-3 ${window.innerWidth <= 576 ? "flex-column" : ""}`} style={{ gap: 2 }}>
        {prices.map((prr, key) => (
          <Card className={`price-card ${window.innerWidth <= 576 ? "border-radius-15 mb-2 " : ""}`} key={prr.id + "-" + key}>
            <Card.Body>
              <p style={{ fontWeight: 400, textAlign: isRtl ? 'right' : 'left', marginBottom: 5 }}>{prr.brand_name}</p>
              <h2 className={`mb-0 ${isRtl ? 'text-right' : 'text-left'}`}>{renderPrice(prr, selectedCurrency)}</h2>
            </Card.Body>
          </Card>
        ))}
      </div>
    );
  } else {
    return (
      <Card className="mr-2 price-card">
        <Card.Body>
          <Placeholder animation="glow">
            <Placeholder style={{ width: "100%", minHeight: 10 }} />
            <div className="d-flex flex-row" style={{ justifyContent: "space-between" }}>
              <Placeholder style={{ width: "25%", maringRight: 5 }} />
              <Placeholder style={{ width: "70%" }} />
            </div>
          </Placeholder>
        </Card.Body>
      </Card>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    prices: state.home.selectedItem.prices,
    selectedCurrency: state.home.selected_currency
  };
};

export default connect(mapStateToProps)(Prices);