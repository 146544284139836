import { Container } from "react-bootstrap"

const About = () => {

    return (
        <Container className="mt-5 about-sec" style={{marginBottom: 100}}>
            <h2>What we really do?</h2>
            <p>We gather mobile data from different site and display our user price difference.</p>

            <h2>Our Vision</h2>
            <p>Our vision is provide user the best and actual price of mobile.</p>

            <h2>History of the Company</h2>
            <p>Our company establish in 2015 and working on different projects like delivery app and website development.</p>

            <h2>Cooperate with Us!</h2>
            <p>Cooperate with us we will give you the best cheepest address of website where you can buy your product online.</p>
        </Container>
    );
}

export default About;