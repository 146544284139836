import { Placeholder } from "react-bootstrap";

const ProductSliderShimarLayout = () => {
  return (
    <>
      <Placeholder
        animation="glow"
        style={{
          border: "0px solid #48afff",
          maxWidth: "100%",
          padding: "15px 30px",
          borderRadius: 0,
          background: "white",
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center"
        }}
      >
        <Placeholder style={{ width: 30, height: 30 }}></Placeholder>
        <Placeholder
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            height: 350,
            width: 180,
            borderRadius: 15,
          }}
        />
        <Placeholder style={{ width: 30, height: 30 }}></Placeholder>
      </Placeholder>
      <Placeholder
        animation="glow"
        className="mt-0 bg-white"
        style={{ paddingBottom: 15, overflowX: "scroll", display: "flex", flexDirection:"row", justifyContent:"center", alignItems:"center" }}
      >
        <Placeholder style={{ height: 50, width: 50, marginRight: 5 }} />
        <Placeholder style={{ height: 50, width: 50, marginRight: 5 }} />
        <Placeholder style={{ height: 50, width: 50, marginRight: 5 }} />
        <Placeholder style={{ height: 50, width: 50, marginRight: 5 }} />
        <Placeholder style={{ height: 50, width: 50 }} />
      </Placeholder>
    </>
  );
};
export default ProductSliderShimarLayout;
