import React, { useEffect, useState } from 'react';
import './../../src/toast.css';
import { connect } from 'react-redux';

const MyToast = ({ alertMessage, setAlertMessage }) => {
    const [isVisible, setIsVisible] = useState(alertMessage?.show);

    useEffect(() => {
        setIsVisible(alertMessage?.show);
        const timeoutId = setTimeout(() => {
            setIsVisible(false);
            setAlertMessage({});
        }, alertMessage?.timeout);

        return () => clearTimeout(timeoutId);
    }, [alertMessage]);

    return (
        <div className="jq-toast-wrap top-right">
            <div className={`jq-toast-single jq-has-icon jq-icon-${alertMessage?.type}`} style={isVisible ? { textAlign: 'left', display: 'block' } : { textAlign: 'left', display: 'none' }}>
                <span className="close-jq-toast-single">×</span>
                <h2 className="jq-toast-heading">{alertMessage?.heading}</h2>
                {alertMessage?.body}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    alertMessage: state.home.alertMessage
});

const dispatchStateToProps = (dispatch) => ({
    setAlertMessage: (obj) => {
        dispatch({
            type: "UPDATE_ALERT_MESSAGE",
            payload: obj
        });
    }
});

export default connect(mapStateToProps, dispatchStateToProps)(MyToast);
