const InstallmentPlan = () => {
    return (
        <div class="section static-section container card mt-3">
            <h1 class="h2 text-center mt-3">Buy Now &amp; Pay Later | Easy Monthly Instalments at Pakmallz</h1>
            <br />
            <p class="p3">
                Pakmallz offers Easy Monthly Instalments with an easy interest-free payment plan to enable its customers to access and purchase the items they want and need while managing their finances during the turbulent economy. With multiple payment tenures and easy interest-free instalments it intends to help people spend responsibly without incurring interest or extended debt.
            </p>
            <p class="p3">
                Easy Monthly Instalment plan (EMI) is available for credit card holders. EMI plan applies for all products
                with a value of Rs. 10,000 or above. Please click the links below to reach terms and conditions of the
                specific plan.

            </p>
            <ol>
                <li class="p2"><a href="https://Pakmallz.pk/bnpl-akbl" target="_blank">Askari Bank</a></li>
                <li class="p2"><a href="https://Pakmallz.pk/bnpl-mcb" target="_blank">MCB</a></li>
                <li class="p2"><a href="https://Pakmallz.pk/bnpl-ubl" target="_blank">UBL</a></li>
                <li class="p2"><a href="https://Pakmallz.pk/bnpl-alfalah" target="_blank">Bank Alfalah</a></li>
                <li class="p2"><a href="https://Pakmallz.pk/bnpl-faysal" target="_blank">Faysal Bank</a></li>
                <li class="p2"><a href="https://Pakmallz.pk/bnpl-silk" target="_blank">SilkBank</a></li>
                <li class="p2"><a href="https://Pakmallz.pk/bnpl-js" target="_blank">JS Bank</a></li>
            </ol>

            <p class="p3  ">Pakmallz will charge 2.5% service fee on every instalment plan.</p>
            <div class="divider"></div>

            <div>
                <h2 class="h3 text-primary">How to buy on instalments from Pakmallz.pk?</h2>
                <br />
                <ol>
                    <li class="p3">To buy from Pakmallz on (EMI) instalment, first choose your product and add it to your cart.</li>
                    <li class="p3">The cart should only contain one product worth at least Rs. 10,000/-.</li>
                    <li class="p3">Click Check Out, Proceed to Payment, and then choose Instalment as your payment method.</li>
                    <li class="p3">Choose your bank, the length of your EMI, and enter your credit card information.</li>
                    <li class="p3">A code will be sent to your registered phone number and email address. Enter the code and place your order.</li>
                    <li class="p3">Please remember that your EMI plan may take up to 7 working days to activate. This is the time between placing your order and receiving your bill. Once your EMI plan has processed, you will receive a SMS from your bank.</li>
                </ol>

                <br />

                <h2 class="h3 text-primary">I was trying to process my order on BNPL but my session expired. What should I do now?</h2>

                <br />

                <div class="p3">You can now proceed with the pending payment by logging into your profile  and clicking on the Pay Now option.
                    <a href="https://Pakmallz.pk/account" target="_blank">https://Pakmallz.pk/account</a>
                </div>

                <br />
                <h2 class="h3 text-primary">Can I opt for an installment plan with my debit card?</h2>
                <br />

                <div class="p3">
                    You can only choose an installment plan if  you have a credit card.

                </div>

                <br />
                <h2 class="h3 text-primary"> Do you offer installments for HBL Bank?</h2>
                <br />

                <div class="p3">
                    We are currently offering installment on credit cards of following banks only.

                    <ol>
                        <br />
                        <li>Bank Alfalah </li>
                        <li>UBL</li>
                        <li>MCB</li>
                        <li>Askari Bank</li>
                        <li>Silk Bank</li>
                        <li>JS Bank</li>
                        <li>Faysal Bank
                        </li>
                    </ol>
                </div>

                <br />
                <h2 class="h3 text-primary">Why has my full amount been reserved by the bank?</h2>
                <br />

                <div class="p3">
                    The complete payment of your purchase is reserved by your Bank and not deducted. The Bank then releases the amount to the available limit as the installment plan successfully proceeds toward its completion. You can contact your bank for further verification.

                </div>

                <br />



                <br />
                <h2 class="h3 text-primary">Will my processing fee and installment amount be refunded if I cancel my BNPL Order ?</h2>
                <br />

                <div class="p3">
                    On cancellation of an order by the customer, the processing fee will be non-refundable.
                    Whereas cancellation of the order by the company for reasons like out of stock or unforeseen events, the company will return the total amount along with the processing fee.


                </div>

                <br />




                <br />
                <h2 class="h3 text-primary">Why is my BNPL request canceled?
                </h2>
                <br />

                <div class="p3">
                    BNPL orders do not proceed if the total amount to be paid exceeds your card limit. The total amount includes (product price + EMI plan chosen + Processing fee + service charges).


                </div>

                <br />


                <a href="https://Pakmallz.pk/terms" target="_blank">*Terms and conditions apply.<p></p>


                </a>
            </div>
            <a href="https://Pakmallz.pk/terms" target="_blank">

            </a>
        </div>
    );
}

export default InstallmentPlan;