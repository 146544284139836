import { Button, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { renderPrice } from "../../helpers/common";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../helpers/api";
import { handleImageError } from "../../helpers/common";

const Wishlist = ({ selectedCurrency }) => {
  const [wishlist, setWishlist] = useState([]);
  useEffect(() => {
    try {
      // Retrieve wishlist from localStorage
      const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];

      // Update the wishlist state variable
      setWishlist(storedWishlist);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const removeItemFromWishlist = (id) => {
    try {
      // Retrieve the wishlist from localStorage
      const storedWishlist = JSON.parse(localStorage.getItem("wishlist")) || [];

      // Find the index of the item with the matching key
      const itemIndex = storedWishlist.findIndex((item) => item.item_id === id);

      // If the item is found, remove it from the wishlist
      if (itemIndex !== -1) {
        storedWishlist.splice(itemIndex, 1);

        // Update the wishlist in localStorage
        localStorage.setItem("wishlist", JSON.stringify(storedWishlist));

        // Update the wishlist state variable, if needed
        setWishlist(storedWishlist);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <div className="mb-2 mt-2">
        <h1 className="text-center">My wishlist on Pakmallz</h1>
      </div>
      <div className="mb-16 wishlist-table">
        <form className="mb-4" action="#" method="post">
          <div className="table-responsive">
            <table className="table" cellSpacing="0">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>Mobile</th>
                  <th>Price Rs</th>
                  <th>Price USD</th>
                </tr>
              </thead>
              <tbody>
                {wishlist && wishlist.length > 0 ? (
                  wishlist.map((item, key) => (
                    <tr key={key}>
                      <td className="text-center">
                        <Link
                          as={Button}
                          onClick={() => removeItemFromWishlist(item.item_id)}
                        >
                          <i className="fa fa-close"></i>
                        </Link>
                      </td>
                      <td className=" d-md-table-cell">
                        <Link to="#">
                          <img
                            className="img-fluid max-width-100 p-1 border border-color-1"
                            src={`${BASE_URL}/${item.thumbnail}`}
                            alt="mobile-thumbnail"
                            onError={handleImageError}
                            style={{ width: 50, height: 70 }}
                          />
                        </Link>
                      </td>

                      <td data-title="Product">
                        <Link to={`/product/detail/${item.id}/${item.item_name.replace(/\s+/g, '-').replace(/'/g, '')}`} className="text-gray-90">
                          {item.item_name}
                        </Link>
                      </td>

                      <td data-title="Unit Price">
                        {item.prices && item.prices.length > 0 && item.prices.map((pr) => (
                          <p className="text-center btn btn-outline-primary me-2 mb-0">
                            <span>
                              {pr.price_rs <= "0" ? renderPrice({ price_rs: pr.expected_price }, selectedCurrency) : renderPrice({ price_rs: pr.price_rs }, selectedCurrency)}
                            </span>
                            <small>{pr.brand_name}</small>
                          </p>
                        ))}
                      </td>
                      <td data-title="Unit Price">
                        unit price
                        <span className="text-blue">
                          {item.prices && item.prices.length > 0
                            ? "$" + item.prices[0].price_usd
                            : renderPrice(
                              { price_usd: -1 },
                              selectedCurrency
                            )}
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">
                      <span className="text-red">Wishlist is empty!</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </form>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  selectedCurrency: state.home.selected_currency,
});

const dispatchStateToProps = (dispatch) => ({});

export default connect(mapStateToProps, dispatchStateToProps)(Wishlist);
