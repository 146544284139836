const FAQs = () => {
    return (
        <div className="container-fluid">
            <div id="faqs-static" className="list-page static-section card mt-3 p-3">
                <div className="top-header header-box">
                    <h2 className="h1 mt-3">Frequently Asked Questions</h2>
                    <div className="help-box text-gray bold p3">
                        Still need help?{' '}
                        <a href="/contact-us" target="_blank" className="faq-btn">
                            Contact Us
                        </a>
                    </div>
                </div>

                <div className="static-info">
                    <div className="static-categories">
                        <a href="#terms" className="category-item">
                            Terms and Conditions
                        </a>

                        <a href="#login" className="category-item">
                            Sign Up and Login
                        </a>
                        <a href="#order-tracking" className="category-item">
                            Shipping, Order Tracking & Delivery
                        </a>

                        <a href="#cancellation" className="category-item">
                            Cancellations and Modifications
                        </a>

                        <a href="#returns" className="category-item">
                            Returns and Replacement
                        </a>

                        <a href="#payment" className="category-item">
                            Payments
                        </a>
                        <a href="#bnpl" className="category-item">
                            Buy Now Pay Later at Installments
                        </a>
                        <a href="#open-parcel" className="category-item">
                            Open Parcel Delivery
                        </a>

                        <a href="#self-pickup" className="category-item">
                            Self pick-up
                        </a>
                        <a href="#coupon" className="category-item">
                            Coupons
                        </a>
                    </div>
                    <div className="static-detail">
                        <div id="terms" className="section-info">
                            {/* Your content here */}
                        </div>

                        <div id="order-tracking" className="section-info ">
                            {/* Your content here */}
                        </div>

                        <div id="cancellation" className="section-info">
                            {/* Your content here */}
                        </div>

                        <div id="returns" className="section-info">
                            {/* Your content here */}
                        </div>

                        <div id="login" className="section-info">
                            {/* Your content here */}
                        </div>

                        <div id="payment" className="section-info">
                            {/* Your content here */}
                        </div>

                        <div id="coupon" className="section-info">
                            {/* Your content here */}
                        </div>

                        <div id="bnpl" className="section-info">
                            {/* Your content here */}
                        </div>

                        <div id="open-parcel" className="section-info">
                            {/* Your content here */}
                        </div>

                        <div id="self-pickup" className="section-info">
                            {/* Your content here */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQs;
